<template>
  <v-card
    class="card-shadow border-radius-xl"
    min-height="60vh"
    style="position: relative"
  >
    <v-window
      v-model="tab"
      class="mb-15"
      style="height: 100% !important; overflow-y: auto"
    >
      <v-window-item value="start" class="mb-10">
        <v-toolbar elevation="0">
          <v-btn
            class="bg-light"
            elevation="0"
            @click="
              $store.dispatch('getProjects', selectedProject.companyId);
              closeEditProjectModal();
            "
          >
            <v-icon size="15" class="">fas fa-times</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Projekt bearbeiten</div>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            class="bg-danger text-white ml-2"
            elevation="0"
            @click="showWarningAlert()"
          >
            <v-icon size="15" class="">fas fa-trash</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row class="pa-0 ma-0 mx-2">
          <v-col cols="12"
            ><v-card class="border-radius-lg">
              <v-row class="justify-center pb-5 px-9">
                <div class="text-center">
                  <v-avatar
                    size="150"
                    class="ma-3 text-uppercase text-dark bg-gradient-warning"
                  >
                    <v-img
                      v-if="!previewUrl"
                      :src="getImage(selectedProject.imagePath)"
                      :alt="selectedProject.title"
                      v-on:error="onImgError"
                    />
                    <v-img
                      v-else
                      :src="previewUrl"
                      :alt="userInformations.firstname"
                      v-on:error="onImgError"
                    />
                  </v-avatar>
                  <div
                    class="my-2 text-warning"
                    style="cursor: pointer"
                    @click="launchFilePicker"
                  >
                    Projektbild wählen
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    style="display: none"
                    ref="file"
                    v-uploader
                  />
                </div>
              </v-row> </v-card></v-col
        ></v-row>

        <v-divider></v-divider>
        <v-form
          v-model="validationState"
          ref="registerCompanyForm"
          @submit.prevent
        >
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">Projektname</span>
            </div>
            <v-text-field
              placeholder="Musterprojekt"
              class="rounded-lg mt-1"
              v-model="selectedProject.title"
              filled
              rounded
              dense
              :rules="formRules.projectNameRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <v-list class="pa-0">
            <v-list-item @click="tab = 'category'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Kategorie</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  {{ selectedProject.category }}
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'manager'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Projektmanager</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  <span v-if="selectedProject.manager">
                    {{
                      `${selectedProject.manager.firstname || ""} ${
                        selectedProject.manager.lastname || ""
                      }`
                    }}</span
                  >
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'customer'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Kunde</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  <span v-if="selectedProject.customer"
                    >{{
                      `${selectedProject.customer.name || ""} ${
                        selectedProject.customer.legalForm || ""
                      }`
                    }}
                  </span>
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'volume'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Auftragsvolumen</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  {{
                    selectedProject.orderValue
                      ? selectedProject.orderValue + " €"
                      : ""
                  }}
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'phases'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Projektphasen</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md"></div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-form>

        <div class="pa-2 px-3 pt-3" v-if="selectedProject.phases.length">
          <draggable
            v-model="selectedProject.phases"
            group="phases"
            :force-fallback="true"
            :options="{ handle: '.area' }"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              class="bg-light mb-3 font-weight-bold border-radius-lg pa-3"
              v-for="phase in selectedProject.phases"
              :key="phase.id"
            >
              <v-row class="align-center pa-0 ma-0">
                <v-row class="align-center"
                  ><v-avatar
                    size="30"
                    class="ma-3 text-uppercase text-dark"
                    :color="phase.done ? '#edd680' : 'grey'"
                  >
                    <v-icon
                      size="8"
                      :class="phase.done ? 'text-dark' : 'text-white'"
                      class="pa-2"
                      >fas fa-check</v-icon
                    >
                  </v-avatar>
                  <div>
                    {{ phase.title }}
                  </div></v-row
                >

                <v-icon
                  size="20"
                  class="text-secondary area"
                  style="cursor: move"
                  >fas fa-grip-lines</v-icon
                >
              </v-row>
            </div></draggable
          >
        </div>

        <!-- <v-card-actions>
          <v-btn
            block
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
            small
            @click="submitForm()"
            ><span v-if="!loadingProjects">Speichern</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular
          ></v-btn>
        </v-card-actions> -->
      </v-window-item>
      <v-window-item value="category">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Kategorien</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pa-0">
          <div v-for="(category, index) in categories" :key="category + index">
            <v-list-item @click="selectCategory(category.title)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md">{{
                  category.title
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-window-item>
      <v-window-item value="manager">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Teammitglieder</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            @click="openAddTeammemberModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 ml-2"
          >
            <v-icon class="text-secondary">fas fa-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <Teammember
          class="mx-2"
          :user="selectedCompany.creator"
          :company="selectedCompany"
          @setUser="setUser"
        />

        <Teammember
          class="mx-2"
          v-for="(teammember, index) in selectedCompany.members"
          :key="index"
          :user="teammember.member"
          :company="teammember.company"
          @setUser="setUser"
        />
      </v-window-item>
      <v-window-item value="customer">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Kunden</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            @click="openAddCustomerModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 ml-2"
          >
            <v-icon class="text-secondary">fas fa-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <Customer
          class="mx-2"
          v-for="(customer, index) in customers.data"
          :key="index"
          :customer="customer"
          @setCustomer="setCustomer"
        />
      </v-window-item>
      <v-window-item value="volume">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Auftragsvolumen</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>

        <div class="mx-4 mt-3">
          <div>
            <span class="font-weight-bolder">Auftragsvolumen (Netto) in €</span>
          </div>
          <v-text-field
            class="rounded-lg mt-1"
            v-model="selectedProject.orderValue"
            type="number"
            filled
            rounded
            dense
          ></v-text-field>
        </div>
        <!-- <v-card-actions>
          <v-btn
            block
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
            small
            @click="tab = 'start'"
            ><span>Speichern</span>
          </v-btn>
        </v-card-actions> -->
      </v-window-item>
      <v-window-item value="phases" :key="selectedProject.phases.length">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Projektphasen</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>

        <v-list class="pa-0">
          <div
            v-for="(projectPhase, index) in projectPhases"
            :key="projectPhase.title + index"
          >
            <v-list-item @click="selectPhase(projectPhase)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >{{ projectPhase.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <div>
                  <v-icon
                    v-if="
                      selectedProject.phases.find(
                        (phase) => phase.id == projectPhase.id
                      )
                    "
                    class="text-success"
                    >fas fa-check</v-icon
                  >
                  <v-icon v-else class="text-secondary">fas fa-plus</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-window-item>
      <v-window-item value="default"></v-window-item>
    </v-window>
    <v-card-actions
      class="pb-2 pt-2 bg-white shadow"
      style="position: absolute; bottom: 0; left: 0; right: 0"
      v-if="tab == 'start'"
    >
      <v-btn
        block
        elevation="0"
        :ripple="false"
        height="43"
        class="mb-2 font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
        small
        @click="submitForm()"
        ><span v-if="!loadingProjects">Speichern</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>
    
<script>
import Teammember from "../Cards/Teammember.vue";
import draggable from "vuedraggable";
import Customer from "../Cards/Customer.vue";

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "EditProjectModal",

  data() {
    return {
      firstChange: false,
      fileInput: null,
      previewUrl: "",
      validationState: false,
      tab: "start",
      items: [{ text: "Rechtsform", icon: "fas fa-chevron-right" }],
    };
  },
  directives: {
    uploader: {
      bind(el, binding, vnode) {
        el.addEventListener("change", (e) => {
          vnode.context.onFileChange(e);
          if (e.target.files[0] !== undefined) {
            vnode.context.fileInput = e.target.files[0];
            vnode.context.onFileChange(e);
          }
        });
      },
    },
  },
  watch: {
    customTab() {
      if (this.customTab) {
        this.tab = this.customTab;
      }
    },
    selectedProject: {
      handler: function (newValue) {
        this.firstChange = true;
      },
      deep: true,
    },
  },
  components: { Teammember, draggable, Customer },
  mounted() {
    if (this.customTab) {
      this.tab = this.customTab;
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange: function (event) {
      const file = this.fileInput;
      if (!file) {
        return false;
      }
      if (!file.type.match("image.*")) {
        return false;
      }
      const reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        that.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    showWarningAlert() {
      this.$swal({
        title: "Bist du dir sicher, dass du das Projekt löschen möchtest?",
        text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          axiosAuth
            .delete("/projects/" + this.selectedProject.id, {
              headers: {
                version: "v1.1",
              },
            })
            .then((res) => {
              this.$store.commit("loadingProjects", false);
              this.$store.dispatch(
                "getProjects",
                this.selectedProject.companyId
              );
              this.$store.commit("editProjectModal", false);
              this.$swal.fire(
                "Gelöscht!",
                "Das Projekt wurde gelöscht.",
                "success"
              );
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error.response);
              this.$store.commit("authError", true);
              this.$store.commit("authMessage", error.response.data.message);
              this.$store.commit("loadingProjects", false);
              this.$swal.fire(
                "Löschen abgebrochen",
                `Fehler bei der Anfrage: ${error?.response?.data?.message}`,
                "error"
              );
            });
          // this.selectedTask.companyId = this.selectedCompany.id;
          // this.$store.dispatch("deleteProject", this.selectedProject);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
    async submitForm() {
      this.$refs.registerCompanyForm.validate();
      if (this.validationState) {
        this.selectedProject.companyId = this.selectedCompany.id;

        this.$store.dispatch("updateProject", this.selectedProject);
        if (this.fileInput) {
          await axiosAuth
            .post("/projects/upload", {
              image: this.previewUrl.split("base64,")[1],
              projectId: this.selectedProject.id,
            })
            .then((res) => {
              this.fileInput = null;
              this.previewUrl = null;
              this.$store.dispatch("getProjects", this.selectedCompany.id);
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      }
    },
    selectCategory(category) {
      this.selectedProject.category = category;
      this.tab = "start";
    },
    selectPhase(projectPhase) {
      if (
        this.selectedProject.phases.find(
          (element) => element.id == projectPhase.id
        )
      ) {
        this.removeObjectWithId(this.selectedProject.phases, projectPhase.id);
      } else {
        this.selectedProject.phases.push(projectPhase);
      }
    },
    setUser(value) {
      if (value.manager) {
        this.selectedProject.manager.manager = value;
        this.selectedProject.managerId.manager = value.id;
      } else {
        this.selectedProject.manager = value;
        this.selectedProject.managerId = value.id;
      }

      this.tab = "start";
    },
    setCustomer(value) {
      this.selectedProject.customer = value.customer;
      this.selectedProject.customerId = value.customerId;
      this.tab = "start";
    },
  },
};
</script>
    
    <style>
</style>