const coreData = {
    filters: {

    },
    data() {
        return {
            newTask: "",
            months: [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember",
            ],
            monthLabels: [
                "Jan",
                "Feb",
                "Mär",
                "Apr",
                "Mai",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Dez",
            ],
            // days: [
            //     "1",
            //     "2",
            //     "3",
            //     "4",
            //     "5",
            //     "6",
            //     "7",
            //     "8",
            //     "9",
            //     "10",
            //     "11",
            //     "12",
            //     "13",
            //     "14",
            //     "15",
            //     "16",
            //     "17",
            //     "18",
            //     "19",
            //     "20",
            //     "21",
            //     "22",
            //     "23",
            //     "24",
            //     "25",
            //     "26",
            //     "27",
            //     "28",
            //     "29",
            //     "30",
            //     "31",
            // ],
            years: ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"],
            categories: [
                { title: "App Entwicklung" },
                { title: "Web Entwicklung" },
                { title: "Logo Design" },
                { title: "..." },
            ],
            types: [
                { title: "NDA" },
                { title: "Angebot" },
                { title: "Abschlagsrechnung" },
                { title: "..." },
            ],
            legalforms: [
                { title: "Einzelunternehmen" },
                { title: "GbR" },
                { title: "e.K." },
                { title: "OHG" },
                { title: "KG" },
                { title: "GmbH" },
                { title: "GmbH & Co KG" },
                { title: "UG (Haftungsbeschränkt)" },
                { title: "AG" },
            ],
            projectPhases: [
                { title: 'Projektstart', done: false, id: 1 },
                { title: 'Planung', done: false, id: 2 },
                { title: 'Durchführung', done: false, id: 3 },
                { title: 'Testphase', done: false, id: 4 },
                { title: 'Projektabschluss', done: false, id: 5 },
            ],
            colors: [
                { colorName: 'Gelb', colorCode: 'F7C744' },
                { colorName: 'Rot', colorCode: 'A1201B', },
                { colorName: 'Orange', colorCode: 'EB742E' },
                { colorName: 'Pink', colorCode: 'B43A6B' },
                { colorName: 'Blau', colorCode: '1934A5' },
                { colorName: 'Hellblau', colorCode: '3887BE' },
                { colorName: 'Grün', colorCode: '3B8445' },
                { colorName: 'Olivgrün', colorCode: '78A542' },
            ],
            formRules: {
                companyIdRules: [
                    (value) => {
                        if (value) return true;

                        return "Firmen-ID wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 6) return true;

                        return "Firmen-ID muss mindestens 6 Zeichen lang sein";
                    },
                ],
                companyNameRules: [
                    (value) => {
                        if (value) return true;

                        return "Firmenname wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Firmenname muss mindestens 2 Zeichen lang sein";
                    },
                ],
                phoneRules: [
                    (value) => {
                        const validationRegex = /^\d{10}$/;
                        if (value.match(validationRegex)) {
                            return true;
                        }
                    },
                    (value) => {
                        if (!value) return true;
                        if (value?.length >= 10) return true;

                        return "Telefonnummer muss mindestens 10 Zeichen lang sein";
                    },
                ],
                taskRules: [
                    (value) => {
                        if (value) return true;

                        return "Aufgabe wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Aufgabe muss mindestens 2 Zeichen lang sein";
                    },
                ],
                projectNameRules: [
                    (value) => {
                        if (value) return true;

                        return "Projektname wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Projektname muss mindestens 2 Zeichen lang sein";
                    },
                ],
                reasonRules: [
                    (value) => {
                        if (value) return true;

                        return "Anlass wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Anlass muss mindestens 2 Zeichen lang sein";
                    },
                ],
                adressRules: [
                    (value) => {
                        if (value) return true;

                        return "Adresse wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Adresse muss mindestens 2 Zeichen lang sein";
                    },
                ],
                zipRules: [
                    (value) => {
                        if (value) return true;

                        return "Postleitzahl wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 5) return true;

                        return "Postleitzahl muss aus 5 Zahlen bestehen";
                    },
                ],
                streetRules: [
                    (value) => {
                        if (value) return true;

                        return "Straße wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Straße muss mindestens 2 Zeichen lang sein";
                    },
                ],
                cityRules: [
                    (value) => {
                        if (value) return true;

                        return "Ort wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 2) return true;

                        return "Ort muss mindestens 2 Zeichen lang sein";
                    },
                ],
                vatRules: [
                    (value) => {
                        if (value) return true;

                        return "Umsatzsteuer-ID wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 11) return true;

                        return "Umsatzsteuer-ID muss mindestens 11 Zeichen lang sein";
                    },
                ],
                vatValueRules: [
                    (value) => {
                        if (value) return true;

                        return "Umsatzsteuer wird benötigt";
                    },
                    (value) => {
                        if (value?.length >= 1) return true;

                        return "Umsatzsteuer muss aus mindestens 1 Ziffer bestehen";
                    },
                ],
                emailRules: [
                    (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Bitte gebe eine gültige E-Mail-Adresse an",
                ],
            },
        }
    },
    computed: {
        todaysDate() {
            let today = new Date()
            // today.setMonth(8)
            return today
        },
        lastMonthDate() {
            let today = new Date()
            // today.setMonth(8)
            today.setMonth(today.getMonth() - 1)
            return today
        },
        calculatedNotifications() {
            let array = [];

            if (this.selectedType == "provider") {
                if (this.projects && this.projects.data) {
                    this.projects.data.forEach((project) => {
                        project.appointments.forEach((appointment) => {
                            if (
                                appointment.accepted == null &&
                                appointment.sentByCustomer == "1"
                            ) {
                                let newAppointment = {
                                    name: appointment.description,
                                    start: new Date(appointment.rawDate),
                                    end: new Date(appointment.rawDateEnd),
                                    color: "blue",
                                    timed: true,
                                    appointment: appointment,
                                    project: project,
                                };
                                array.push(newAppointment);
                            }
                        });
                    });
                }

                if (this.selectedCompany && this.selectedCompany.serviceProviders) {
                    this.selectedCompany.serviceProviders.forEach(serviceProvider => {
                        if (serviceProvider.accepted == null) {
                            array.push(serviceProvider)
                        }

                    });
                }


            } else {
                if (this.selectedProject) {
                    this.selectedProject.appointments.forEach((appointment) => {
                        if (
                            appointment.accepted == null &&
                            appointment.sentByProvider == "1"
                        ) {
                            let newAppointment = {
                                name: appointment.description,
                                start: new Date(appointment.rawDate),
                                end: new Date(appointment.rawDateEnd),
                                color: "blue",
                                timed: true,
                                appointment: appointment,
                                project: this.selectedProject,
                            };
                            array.push(newAppointment);
                        }
                    });
                }
            }

            return array;
        },
        customTab() {
            return this.$store.getters.customTab;
        },
        getColor() {
            try {
                return this.colors.find(
                    (color) => color.colorCode == this.selectedTask.color
                );
            } catch (error) {
                return { colorName: "Schwarz", colorCode: "000" };
            }
        },
        updatingAuth() {
            return this.$store.getters.updatingAuth;
        },
        accountCreated() {
            return this.$store.getters.updatingAuth;
        },
        selectTypeModal() {
            // this.$store.dispatch('getCompanies');
            // if (this.selectedType == "provider") {
            //     this.$store.dispatch("getProjects", this.selectedCompany.id);

            //     // this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
            //     // this.$store.dispatch("getCustomers", this.selectedCompany.id);
            // } else {
            //     // this.$store.dispatch("getSelectedProject", this.selectedProject.id);
            //     // this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
            // }
            return this.$store.getters.selectTypeModal;
        },
        selectedType() {
            return this.$store.getters.selectedType;
        },
        selectCompanyModal() {
            return this.$store.getters.selectCompanyModal;
        },
        companyInfoModal() {
            return this.$store.getters.companyInfoModal;
        },
        userInformations() {
            return this.$store.getters.userInformations;
        },
        companies() {
            return this.$store.getters.companies;
        },
        tasks() {
            return this.$store.getters.tasks;
        },
        customers() {
            return this.$store.getters.customers;
        },
        teammembers() {
            return this.$store.getters.teammembers;
        },
        projects() {
            return this.$store.getters.projects;
        },
        selectedProject() {
            return this.$store.getters.selectedProject;
        },
        selectedCompany() {
            return this.$store.getters.selectedCompany;
        },
        selectedUser() {
            return this.$store.getters.selectedUser;
        },
        companyDetailModal() {
            return this.$store.getters.companyDetailModal;
        },
        taskDetailModal() {
            return this.$store.getters.taskDetailModal;
        },
        userInfoModal() {
            return this.$store.getters.userInfoModal;
        },
        addTeammemberModal() {
            return this.$store.getters.addTeammemberModal
        },
        logoutModal() {
            return this.$store.getters.logoutModal;
        },
        newProjectModal() {
            return this.$store.getters.newProjectModal;
        },
        selectProjectModal() {
            return this.$store.getters.selectProjectModal;
        },
        editProjectModal() {
            return this.$store.getters.editProjectModal;
        },
        addAppointmentModal() {
            return this.$store.getters.addAppointmentModal;
        },
        addCustomerModal() {
            return this.$store.getters.addCustomerModal;
        },
        loadingCompanies() {
            return this.$store.getters.loadingCompanies;
        },
        loadingAppointments() {
            return this.$store.getters.loadingAppointments;
        },
        loadingProjects() {
            return this.$store.getters.loadingProjects;
        },
        loadingCustomers() {
            return this.$store.getters.loadingCustomers;
        },
        loadingTeammembers() {
            return this.$store.getters.loadingTeammembers;
        },
        chatMessages() {
            return this.$store.getters.chatMessages;
        },
        selectedTask() {
            return this.$store.getters.selectedTask;
        },
        customerInfoModal() {
            return this.$store.getters.customerInfoModal;
        },
        selectedCustomer() {
            return this.$store.getters.selectedCustomer;
        },
        notifications() {
            return this.$store.getters.notifications;
        },
        appointments() {
            return this.$store.getters.appointments;
        },
        resetOk() {
            return this.$store.getters.resetOk;
        },
        findProjectById() {
            try {
                return this.projects.data.find(element => element.id == this.selectedTask.projectId)
            } catch (error) {
                return {}
            }
        },
        labels() {
            let dt = this.todaysDate;
            let month = dt.getMonth() + 1;
            let year = dt.getFullYear();
            let labelArray = [];
            switch (this.selectedFilter) {
                case 1:
                    return this.monthLabels;
                case 2:
                    for (let index = 1; index < daysInMonth(month, year) + 1; index++) {
                        labelArray.push(
                            ("0" + index).slice(-2) +
                            "." +
                            ("0" + month).slice(-2) +
                            "." +
                            year
                        );
                    }
                    return labelArray;

                default:
                    break;
            }

            function daysInMonth(month, year) {
                return new Date(year, month, 0).getDate();
            }
        },
    },
    // watch: {
    //     selectedCompany: {
    //         handler: function (newValue) {
    //             if (this.selectedCompany) {
    //                 this.$store.dispatch('getCustomers', newValue.id)
    //             }
    //         },
    //         deep: true,
    //     },
    // },
    mounted() {

    },

    methods: {
        calculateNotifications(company) {
            let calc = 0;
            let array = [];
            try {
                company.serviceProviders.forEach((element) => {
                    if (element.accepted != true) array.push(element)
                    //  calc += 1;
                });
            } catch (error) {
                console.log(error)
            }
            return array;
        },
        selectCustomer(customer) {
            this.$store.commit("selectedCustomer", customer);
            this.$store.commit("customerInfoModal", true);
        },
        closeCustomerModal() {
            this.$store.commit("selectedCustomer", null);
            this.$store.commit("customerInfoModal", false);
        },
        getColorByColorCode(colorCode) {
            if (!colorCode) return { colorName: "Schwarz", colorCode: "000" };
            try {
                return this.colors.find(
                    (color) => color.colorCode == colorCode
                );
            } catch (error) {
                console.log(error)
                return { colorName: "Schwarz", colorCode: "000" };
            }
        },
        removeObjectWithId(arr, id) {
            const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

            if (objWithIdIndex > -1) {
                arr.splice(objWithIdIndex, 1);
            }

            return arr;
        },
        getImage(link) {
            return process.env.VUE_APP_API_BASEURL + '/' + link
        },
        onImgError(value) {

        },
        selectType(type) {
            this.$store.dispatch('getCompanies');
            this.$store.commit("selectedType", type);
            this.$store.commit("selectedCompany", null);
            this.$store.commit("selectTypeModal", false);
            this.$store.commit("selectCompanyModal", true);
        },
        setSelectTypeModal() {
            this.$store.commit("selectTypeModal", true);
            this.$store.commit("selectCompanyModal", false);
        },
        setSelectCompanyModal() {
            this.$store.commit("selectProjectModal", false);
            this.$store.commit("selectCompanyModal", true);
        },
        setSelectProjectModal() {
            this.$store.commit("selectProjectModal", true);
        },
        selectCompany(company) {
            this.$store.commit("selectedCompany", company);
            this.$store.commit("selectCompanyModal", false);

            if (this.selectedType == 'customer') {
                this.$store.commit("selectProjectModal", true);
                this.$store.dispatch('getProjects', company.id)
            } else {
                this.$store.dispatch('getProjects', company.id)
                this.$store.dispatch('getCustomers', company.id)
                this.$router.push('/dashboard')
            }
        },
        selectProject(project) {
            this.$store.commit("selectedProject", project);

            this.$store.commit("selectProjectModal", false);
            this.$store.dispatch('getSelectedProject', project.id)
            this.$router.push('/dashboard')
        },
        openCustomer(customer) {

        },
        openChat(project) {
            this.$store.commit("selectedProject", project);
            this.$store.commit("chatMessages", []);
            this.$store.dispatch("getMessages", project.id);
            this.$router.push('/chats').catch(err => err = '')
            try {
                var div = document.getElementById("chat-list");
                div.scrollTop = div.scrollHeight;
            } catch (error) {

            }
        },
        closeCompanyDetailModal() {
            this.$store.commit("companyDetailModal", false);
        },
        openCompanyDetailModal() {
            this.$store.commit("companyDetailModal", true);
        },
        closeNewProjectModal() {
            this.$store.commit("newProjectModal", false);
        },
        openNewProjectModal() {
            this.$store.commit("newProjectModal", true);
        },
        closeEditProjectModal() {
            this.$store.commit("editProjectModal", false);
            this.$store.commit('customTab', '');
        },
        openEditProjectModal() {
            this.$store.commit("editProjectModal", true);
        },
        openTaskDetailModal() {
            this.$store.commit("taskDetailModal", true);
        },
        closeTaskDetailModal() {
            this.$store.commit("taskDetailModal", false);
        },
        openAddCustomerModal() {
            this.$store.commit("addCustomerModal", true);
        },
        closeAddCustomerModal() {
            this.$store.commit("addCustomerModal", false);
        },
        openAddTeammemberModal() {
            this.$store.commit("addTeammemberModal", true);
        },
        closeAddTeammemberModal() {
            this.$store.commit("addTeammemberModal", false);
        },
        openCompanyInfoModal() {
            this.$store.commit("companyInfoModal", true);
        },
        closeCompanyInfoModal() {
            this.$store.commit("companyInfoModal", false);
        },
        openNewAppointmentModal() {
            this.$store.commit("addAppointmentModal", true);
        },
        closeNewAppointmentModal() {
            this.$store.commit("addAppointmentModal", false);
        },
        openUserInfoModal(user) {
            if (!user) return;
            this.$store.commit("selectedUser", user);
            this.$store.commit("userInfoModal", true);
        },
        closeUserInfoModal() {
            this.$store.commit("selectedUser", null);
            this.$store.commit("userInfoModal", false);
        },
        submitTask(project) {

            if (this.newTask.length) {
                const taskData = {
                    title: this.newTask,
                    projectId: project.id,
                    companyId: this.selectedCompany.id
                }
                this.$store.dispatch("postTask", taskData);
                this.newTask = "";
            }
        },
        selectTask(task) {
            this.$store.commit("selectedTask", JSON.parse(JSON.stringify(task)));
            if (task) {
                this.openTaskDetailModal();
            } else {
                this.closeTaskDetailModal();
            }
        }
    }
}

export default coreData