<template>
  <div>
    <template>
      <v-footer color="transparent" class="px-6 w-100 bottom-0 mb-10">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6"> </v-col>

              <v-col cols="12" md="6" class="d-flex justify-end">
                <v-row class="ma-0 pa-0">
                  <div class="mx-2" v-for="item in footer" :key="item.linkName">
                    <a
                      :href="item.link"
                      class="text-decoration-none text-dark text-body-2 ls-0 no-default-hover"
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </div>
                </v-row>
                <!-- <ul
                  v-for="item in footer"
                  :key="item.linkName"
                  class="d-flex list-style-none"
                >
                  <li>
                    <a
                      :href="item.link"
                      class="text-decoration-none text-dark text-body-2 ls-0 no-default-hover"
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </li>
                </ul> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </div>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "AGB",
          link: "https://soap-software.de/agb/",
        },
        {
          linkName: "Datenschutzerklärung",
          link: "https://soap-software.de/datenschutz/",
        },
        {
          linkName: "Widerruf",
          link: "https://soap-software.de/widerrufsbelehrung/",
        },
        {
          linkName: "Impressum",
          link: "https://soap-software.de/impressum/",
        },
      ],
    };
  },
};
</script>
