<template>
  <div v-if="customer">
    <v-card @click="trySet()" class="border-radius-md bg-light ma-2 pa-2">
      <div>
        <v-row class="align-center">
          <v-avatar
            size="50"
            class="ma-3 text-uppercase text-dark"
            color="#F3F5F6"
          >
            <div>
              {{ customer.customer.name[0] }}
            </div>
          </v-avatar>
          <v-col>
            <div class="my-1 font-weight-bolder text-md">
              {{ customer.customer.name }}
              {{ customer.customer.legalForm }}
            </div>
            <div class="my-1 font-weight-normal text-md">
              Kunde seit:
              {{ customer.customer.updatedAt | moment("DD.MM.YYYY") }}
            </div></v-col
          >

          <v-icon v-if="customer.accepted" class="text-secondary mr-5"
            >fas fa-chevron-right</v-icon
          >
          <v-chip v-else class="ma-2 bg-orange mr-5 text-white">
            ausstehend
          </v-chip>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  props: {
    customer: null,
  },
  methods: {
    trySet() {
      if (!this.customer.accepted) {
        this.$swal.fire(
          "Einladung ausstehend!",
          "Der Kunde kann erst hinzugefügt werden, wenn die Einladung angenommen wurde.",
          "error"
        );
      } else {
        this.$emit("setCustomer", this.customer);
      }
    },
  },
};
</script>

<style>
</style>