<template>
  <v-app>
    <drawer
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
    >
    </drawer>
    <v-main
      v-if="
        (selectedType == 'provider' && selectedCompany) ||
        (selectedType == 'customer' && selectedProject)
      "
    >
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        v-if="$route.name != 'Profile'"
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        @toggleSettingsDrawer="toggleSettingsDrawer"
      ></app-bar>
      <app-bar
        v-else-if="$route.name == 'Profile'"
        background="bg-default"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <app-bar
        v-else
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <fade-transition
        :duration="200"
        origin="center top"
        mode="out-in"
        v-if="userInformations && selectedType"
      >
        <router-view></router-view>
      </fade-transition>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      <v-btn
        :ripple="false"
        icon
        rounded
        color="#fff"
        width="52px"
        height="52px"
        class="fixed-plugin-button position-fixed btn-light bg-white text-dark z-index-9999"
        @click="showSettingsDrawer = true"
      >
        <div style="position: relative width: 100%; height: 100%;">
          <v-icon size="30">fa fa-bell</v-icon>

          <v-badge
            v-if="notifications.length + calculatedNotifications.length > 0"
            style="
              background-color: red;
              position: absolute;
              top: -10px;
              right: -10px;
              border-radius: 50px;
            "
            color="error"
            :content="notifications.length + calculatedNotifications.length"
            inline
          ></v-badge>
          <!-- <div
            style="
              background-color: red;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 25px;
            "
          ></div> -->
        </div>
      </v-btn>

      <settings-drawer
        :showSettingsDrawer="showSettingsDrawer"
        @toggleSettingsDrawer="toggleSettingsDrawer"
        @updateSidebarColor="updateSidebarColor"
        @updateSidebarTheme="updateSidebarTheme"
        @toggleNavbarPosition="toggleNavbarPosition"
      >
      </settings-drawer>
    </v-main>
    <v-dialog v-model="selectTypeModal" max-width="500px" persistent>
      <SelectType />
    </v-dialog>
    <v-dialog v-model="selectCompanyModal" max-width="500px" persistent>
      <SelectCompany />
    </v-dialog>
    <v-dialog v-model="selectProjectModal" max-width="500px" persistent>
      <SelectProject />
    </v-dialog>

    <v-dialog v-model="companyDetailModal" max-width="500px" persistent>
      <company-detail-modal />
    </v-dialog>

    <v-dialog v-model="newProjectModal" max-width="500px" persistent>
      <new-project-modal />
    </v-dialog>

    <v-dialog v-model="taskDetailModal" max-width="500px" persistent>
      <task-detail-modal />
    </v-dialog>

    <v-dialog v-model="addCustomerModal" max-width="500px" persistent>
      <add-customer-modal />
    </v-dialog>

    <v-dialog v-model="addTeammemberModal" max-width="500px" persistent>
      <add-teammember-modal />
    </v-dialog>

    <v-dialog v-model="addAppointmentModal" max-width="500px" persistent>
      <add-appointment-modal />
    </v-dialog>

    <v-dialog v-model="customerInfoModal" max-width="500px" persistent>
      <CustomerInfo :customer="selectedCustomer" />
    </v-dialog>
    <v-dialog v-model="companyInfoModal" max-width="500px" persistent>
      <CompanyInfo />
    </v-dialog>
    <v-dialog v-model="userInfoModal" max-width="500px" persistent>
      <UserInfo />
    </v-dialog>
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
          >fa fa-bell</v-icon
        >
        <p class="mb-0">
          <span class="font-size-root font-weight-600">Benachrichtigung</span>
          <br />
          {{ snackbarContent }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";
import SelectType from "@/views/Layout/Modals/SelectType.vue";
import SelectCompany from "@/views/Layout/Modals/SelectCompany.vue";
import coreData from "@/mixins/coreDataMixin";
import SelectProject from "./Modals/SelectProject.vue";
import CustomerInfo from "@/components/Modals/CustomerInfo.vue";
import CompanyInfo from "@/components/Modals/CompanyInfo.vue";
import UserInfo from "@/components/Modals/UserInfo.vue";

import { initializeApp } from "firebase/app";
import axiosAuth from "@/shared/configs/axios-auth";

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

const firebaseConfig = {
  apiKey: "AIzaSyC8ifjjo0jFis4nkLQgVVncyVRdvnhtPrg",
  authDomain: "soap-205ae.firebaseapp.com",
  projectId: "soap-205ae",
  storageBucket: "soap-205ae.appspot.com",
  messagingSenderId: "2447778794",
  appId: "1:2447778794:web:59c1e8ab27199588eec893",
  measurementId: "G-18Z8QCDVV9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import { getMessaging, getToken, onMessage } from "firebase/messaging";
const messaging = getMessaging();

export default {
  mixins: [coreData],
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
    SettingsDrawer,
    SelectType,
    SelectCompany,
    SelectProject,
    CustomerInfo,
    CompanyInfo,
    UserInfo,
  },
  data() {
    return {
      snackbarContent: "Du hast eine neue Benachrichtigung erhalten",
      snackbar: {
        color: null,
        visible: false,
      },
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "warning",
      sidebarTheme: "white",
      navbarFixed: false,
      snackbars: [
        {
          color: "#2dce89",
          class: "success",
          name: "Success",
        },
        {
          color: "#11cdef",
          class: "info",
          name: "Info",
        },
        {
          color: "#fb6340",
          class: "warning",
          name: "Warning",
        },
        {
          color: "#f5365c",
          class: "danger",
          name: "Danger",
        },
      ],
      alerts: [
        {
          type: "primary",
        },
        {
          type: "secondary",
        },
        {
          type: "success",
        },
        {
          type: "danger",
        },
        {
          type: "warning",
        },
        {
          type: "info",
        },
        {
          type: "light",
        },
        {
          type: "dark",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    SnackbarShow(type) {
      if (!type) return;
      switch (type) {
        case "Info":
          this.snackbar = {
            color: "#1A73E8",
            visible: true,
          };
          break;
        case "Success":
          this.snackbar = {
            color: "#4CAF50",
            visible: true,
          };
          break;
        case "Warning":
          this.snackbar = {
            color: "#fb8c00",
            visible: true,
          };
          break;
        case "Danger":
          this.snackbar = {
            color: "#F44335",
            visible: true,
          };
          break;
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    initFireBase() {},
  },
  mounted() {
    this.initScrollbar();
    this.$store.dispatch("tryAutoLogin");
    if (
      !this.selectedType ||
      (!this.selectedProject && this.selectedType == "customer")
    ) {
      this.$store.commit("selectTypeModal", true);
    } else {
      this.$store.commit("selectTypeModal", false);
    }

    this.initFireBase();

    onMessage(messaging, (payload) => {
      let notificationArray = this.$store.getters.notifications;
      payload.notification.date = new Date();
      notificationArray.push(payload.notification);
      this.$store.commit("notifications", notificationArray);
      try {
        if (this.selectedType == "provider") {
          this.$store.dispatch("getProjects", this.selectedCompany.id);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
          this.$store.dispatch("getCustomers", this.selectedCompany.id);
        } else {
          this.$store.dispatch("getSelectedProject", this.selectedProject.id);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
        }
      } catch (error) {}
      this.snackbarContent =
        payload.notification.title + " " + payload.notification.body;
      this.SnackbarShow("Success");
    });
    var vm = this;
    getToken(messaging, {
      vapidKey:
        "BEMDqnvz68bR7GwdkWPgRzzGdm4jyOlkDhOcaYaHO93FVPxrIE-c1Tq_Lr7t3ctAslTaGXQUW2I0FtsrUy10eB0",
    })
      .then((currentToken) => {
        if (currentToken) {
          axiosAuth
            .get("/me")
            .then((res) => {
              vm.$store.commit("updating", false);
              vm.$store.commit("userInformations", res.data);
              vm.userInformations.fcmTokenWeb = currentToken;
              vm.$store.dispatch(
                "updateUserInformations",
                this.userInformations
              );
            })
            .catch((error) => {
              console.log(error);
              vm.$store.commit("authMessage", error.response.data);
              vm.$store.commit("authError", error.response.status);
              vm.$store.commit("updating", false);
            });
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  },
};
</script>
<style lang="scss"></style>
