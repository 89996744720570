<template>
  <v-card class="card-shadow border-radius-xl pa-5">
    <div class="card-padding pb-4 white">
      <v-row class="justify-center ma-0 pa-0">
        <div>
          <v-img
            src="@/assets/img/soap.png"
            class=""
            width="150px"
            height="auto"
            contain
          >
          </v-img>
        </div>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <div>
        <h4
          class="text-h3 mt-0 mt-md-5 font-weight-bolder text-uppercase text-typo mb-2"
        >
          Als was möchtest du Soap nutzen?
        </h4>

        <p class="mb-0 text-typo">
          Wähle aus, ob du soap als Dienstleister oder Kunde nutzen möchtest.
        </p>
      </div>
    </div>
    <v-row class="pa-0 ma-0 px-2">
      <v-col>
        <v-btn
          @click="selectType('provider')"
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100"
          color="#5e72e4"
          small
          ><span>Dienstleister</span>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          @click="selectType('customer')"
          elevation="0"
          :ripple="false"
          height="43"
          variant="outlined"
          class="font-weight-bold btn-default bg-white text-default py-2 px-6 me-2 mb-2 w-100"
          small
          ><span>Kunde</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  methods: {},
};
</script>

<style>
</style>