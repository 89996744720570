<template>
  <div v-if="customer">
    <v-card class="card-shadow border-radius-xl pb-4" v-if="customer.customer">
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="closeCustomerModal()">
          <v-icon size="15" class="">fas fa-times</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Kunde</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          class="bg-danger text-white ml-2"
          elevation="0"
          @click="showWarningAlert()"
        >
          <v-icon size="15" class="">fas fa-trash</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list class="pa-0" v-if="customer">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Kunde</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer.customer"
                >{{
                  `${customer.customer.name || ""} ${
                    customer.customer.legalForm || ""
                  }`
                }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <div v-if="customer.accepted == true || customer.accepted == '1'">
          <!-- <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Ansprechpartner</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer.customer"
                >{{
                  `${customer.customer.name || ""} ${
                    customer.customer.legalForm || ""
                  }`
                }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider> -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >E-Mail</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="customer.customer"
                  >{{ `${customer.customer.email || ""}` }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Telefon</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="customer.customer"
                  >{{ `${customer.customer.phone || ""}` }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </div>
        <div v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Status</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-chip
                v-if="customer.accepted == false"
                class="bg-danger text-white"
              >
                Einladung abgelehnt
              </v-chip>
              <v-chip v-if="customer.accepted == null" class="bg-light">
                Einladung ausstehend
              </v-chip>
              <v-chip
                v-if="customer.accepted == true"
                class="bg-success text-white"
              >
                Einladung bestätigt
              </v-chip>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
      <div class="px-5 text-center mt-5" v-if="!customer.accepted">
        <span class="font-weight-bolder"> Versendet: </span>
        <span>
          {{ customer.createdAt | moment("DD.MM.YYYY - HH:mm") }} Uhr
        </span>
      </div>
      <div class="px-5 text-center mt-5" v-else>
        <span class="font-weight-bolder"> Kunde seit: </span>
        <span>
          {{ customer.updatedAt | moment("DD.MM.YYYY - HH:mm") }} Uhr
        </span>
      </div>
    </v-card>
    <v-card class="card-shadow border-radius-xl pb-4" v-else>
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="closeCustomerModal()">
          <v-icon size="15" class="">fas fa-times</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Kunde</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          class="bg-danger text-white ml-2"
          elevation="0"
          @click="showWarningAlert()"
        >
          <v-icon size="15" class="">fas fa-trash</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list class="pa-0" v-if="customer">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Firma</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer"
                >{{ `${customer.name || ""} ${customer.legalForm || ""}` }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Adresse</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer">{{ `${customer.street || ""}` }} </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Postleitzahl</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer">{{ `${customer.zip || ""}` }} </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Ort</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer">{{ `${customer.city || ""}` }} </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >E-Mail</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer">{{ `${customer.email || ""}` }} </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Telefon</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="customer">{{ `${customer.phone || ""}` }} </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-card>
  </div>
</template>
    
<script>
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "CustomerInfoModal",
  props: {
    customer: null,
  },
  data() {
    return {};
  },

  methods: {
    showWarningAlert() {
      this.$swal({
        title: "Bist du dir sicher, dass du den Kunden löschen möchtest?",
        text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$store.commit("loadingCustomers", true);
          axiosAuth
            .delete("/customers/" + this.customer.id, {
              headers: {
                version: "v1.1",
              },
            })
            .then((res) => {
              this.$store.commit("loadingCustomers", false);
              this.$store.dispatch("getCustomers", this.selectedCompany.id);
              this.closeCustomerModal();
              this.$swal.fire(
                "Gelöscht!",
                "Der Kunde wurde gelöscht.",
                "success"
              );
              this.$emit("close", true);
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit("loadingCustomers", false);
              this.$store.dispatch("getCustomers", this.selectedCompany.id);
              this.$swal.fire(
                "Fehler bei der Anfrage!",
                "Der Kunde konnte nicht gelöscht werden.",
                "error"
              );
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
  },
  computed: {},
};
</script>