<template>
  <v-card class="card-shadow border-radius-xl pb-4">
    <div>
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="setSelectCompanyModal()">
          <v-icon size="15" class="text-secondary">fas fa-chevron-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div class="white">
        <v-row class="justify-center ma-0 pa-0 mb-10">
          <div>
            <v-img
              src="@/assets/img/soap.png"
              class=""
              width="150px"
              height="auto"
              contain
            >
            </v-img>
          </div>
        </v-row>
      </div>

      <v-divider></v-divider>
      <v-row class="align-center pa-0 ma-0 px-2">
        <v-avatar
          size="50"
          class="ma-3 text-uppercase text-dark"
          color="#F3F5F6"
        >
          <v-img
            :src="getImage(userInformations.avatar)"
            :alt="userInformations.firstname"
            v-on:error="onImgError"
          />
        </v-avatar>
        <v-col>
          <div class="my-1 font-weight-bolder text-md">
            Hallo {{ userInformations.firstname }}
          </div>
          <div class="my-1 font-weight-normal text-md">
            <p class="mb-0 text-typo">Wähle ein Projekt!</p>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div class="my-1 font-weight-bolder text-h5 px-5 pt-4 mb-2">Projekte</div>
      <div
        v-if="
          selectedCompany.customerProjects &&
          selectedCompany.customerProjects.length > 0
        "
      >
        <div
          class="mb-2 bg-light pa-3 border-radius-lg mx-3"
          style="cursor: pointer"
          v-for="project in selectedCompany.customerProjects"
          :key="project.id"
          @click="selectProject(project)"
        >
          <v-row class="align-center">
            <v-avatar
              size="50"
              class="ma-3 text-uppercase text-dark"
              color="grey"
            >
              <v-img
                :src="getImage(project.imagePath)"
                :alt="project.title"
                v-on:error="onImgError"
              />
            </v-avatar>
            <v-col>
              <div>
                <div class="text-dark">
                  {{ project.title }}
                </div>
                <div class="text-body">
                  <span v-if="project.serviceProvider">
                    {{
                      `${project.serviceProvider.name || ""} ${
                        project.serviceProvider.legalForm || ""
                      }`
                    }}</span
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <div class="my-3 px-5 text-start text-body">
          Dir wurden noch keine Projekte zugewiesen.
        </div>
      </div>
      <v-divider></v-divider>
      <div class="my-3 mt-5 px-5 text-center text-body">
        Wähle ein Projekt aus.
      </div>
      <div class="my-3 px-5 text-center text-body">
        Ist noch keines vorhanden, sage deinem Auftraggeber bescheid.
      </div>
      <div class="my-3 px-5 text-center text-body">
        Firmen-ID: {{ selectedCompany.generatedId }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return { tab: null };
  },
  methods: {
    selectType(project) {
      this.$store.commit("selectedProject", project);
      this.$store.commit("selectCompanyModal", false);
      // this.selectCompanyModal = false;
    },
  },
  computed: {},
};
</script>

<style>
</style>