<template>
  <div class="fixed-plugin" :class="showSettingsDrawer == true ? 'show' : ''">
    <v-card class="shadow-lg pa-0">
      <div class="">
        <v-row class="pa-0 ma-0 justify-space-between">
          <v-col>
            <div>
              <h5 class="text-h5 text-dark font-weight-bold mt-1 mb-0">
                Benachrichtigungen
              </h5>
            </div>
          </v-col>
          <div class="mt-4 mr-5">
            <v-btn
              :ripple="false"
              icon
              rounded
              width="20px"
              height="20px"
              @click="$emit('toggleSettingsDrawer', false)"
            >
              <v-icon size="10">fas fa-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>
      <hr class="horizontal dark" />
      <v-tabs v-model="tab" background-color="transparent" grow touchless>
        <v-tab :ripple="false" href="#regular">
          <span class="ms-1"
            >Allgemein ({{ calculatedNotifications.length }})</span
          >
        </v-tab>

        <v-tab :ripple="false" href="#system">
          <span class="ms-1">System ({{ notifications.length }}) </span>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab" touchless>
        <v-window-item value="regular">
          <div
            class="pa-5 text-center"
            v-if="calculatedNotifications.length == 0"
          >
            Du hast aktuell keine Benachrichtigungen
          </div>
          <div class="pa-3 text-start" v-else>
            <div
              class="border-radius-lg bg-light mb-3"
              v-for="(notification, index) in calculatedNotifications"
              :key="index"
            >
              <div v-if="notification.project" class="pa-3">
                <v-row class="align-center mb-1">
                  <v-avatar
                    size="50"
                    class="ma-3 text-uppercase text-dark"
                    color="grey"
                  >
                    <v-img
                      :src="getImage(notification.project.imagePath)"
                      :alt="notification.project.title"
                      v-on:error="onImgError"
                    />
                  </v-avatar>
                  <v-col>
                    <div class="font-weight-bolder text-md">
                      {{ `${notification.project.title}` }}
                    </div>
                    <div
                      v-if="notification.project.serviceProvider"
                      class="font-weight-normal text-md"
                    >
                      {{ `${notification.project.serviceProvider.name}` }}
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bolder text-md"
                      >Termin</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <div class="mr-3 pt-1 secondary--text text-md">
                      <span v-if="notification.appointment.rawDate"
                        >{{
                          notification.appointment.rawDate
                            | moment("DD.MM.YYYY - HH:mm")
                        }}
                      </span>
                    </div>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bolder text-md"
                      >Ansprechpartner</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <div class="mr-3 pt-1 secondary--text text-md">
                      <span v-if="notification.project.manager"
                        >{{
                          `${notification.project.manager.firstname} ${notification.project.manager.lastname}`
                        }}
                      </span>
                    </div>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>

                <v-btn
                  @click="editAppointment(notification.appointment)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="mt-3 font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100"
                  color="#5e72e4"
                  small
                  ><span v-if="!loadingAppointments">Annehmen</span
                  ><v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>

                <v-btn
                  @click="deleteAppointment(notification.appointment)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  variant="outlined"
                  class="font-weight-bold btn-default bg-white text-default py-2 px-6 me-2 mb-2 w-100"
                  small
                  ><span v-if="!loadingAppointments">Ablehnen</span
                  ><v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </div>
              <div v-if="notification.serviceProvider" class="pa-3">
                <v-row class="align-center">
                  <v-col>
                    <div class="text-md">
                      <span class="font-weight-bolder">
                        {{ `${notification.serviceProvider.name}` }}
                      </span>
                      möchte dich als Kunde hinzufügen
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-btn
                  @click="editCustomer(notification)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="mt-3 font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100"
                  color="#5e72e4"
                  small
                  ><span v-if="!loadingAppointments">Annehmen</span
                  ><v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>

                <v-btn
                  @click="deleteCustomer(notification)"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  variant="outlined"
                  class="font-weight-bold btn-default bg-white text-default py-2 px-6 me-2 mb-2 w-100"
                  small
                  ><span v-if="!loadingAppointments">Ablehnen</span
                  ><v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </div>
          </div>
        </v-window-item>
        <v-window-item value="system">
          <div class="pa-5 text-center" v-if="notifications.length == 0">
            Du hast aktuell keine Benachrichtigungen
          </div>
          <div class="pa-3 text-start" v-else>
            <div
              class="border-radius-lg bg-light pa-3 mb-3"
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <v-row class="ma-0 pa-0 justify-space-between">
                <div class="font-weight-bold mt-1">
                  {{ notification.title }}
                </div>
                <v-btn
                  icon
                  width="31"
                  :ripple="false"
                  @click="removeNotification(index)"
                >
                  <v-icon size="18" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-row>

              <div>
                {{ notification.body }}
              </div>

              <small>
                {{ notification.date | moment("DD.MM.YYYY HH:mm") }}
              </small>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>
<script>
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  props: {
    showSettingsDrawer: {
      type: Boolean,
      default: false,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // calculatedNotifications() {
    //   return [];
    // },
    // calculatedNotifications() {
    //   let array = [];
    //   if (this.selectedType == "provider") {
    //     if (this.projects && this.projects.data) {
    //       this.projects.data.forEach((project) => {
    //         project.appointments.forEach((appointment) => {
    //           if (
    //             appointment.accepted == "0" &&
    //             appointment.sentByCustomer == "1"
    //           ) {
    //             let newAppointment = {
    //               name: appointment.description,
    //               start: new Date(appointment.rawDate),
    //               end: new Date(appointment.rawDateEnd),
    //               color: "blue",
    //               timed: true,
    //               appointment: appointment,
    //               project: project,
    //             };
    //             array.push(newAppointment);
    //           }
    //         });
    //       });
    //     }
    //   } else {
    //     if (this.selectedProject) {
    //       this.selectedProject.appointments.forEach((appointment) => {
    //         if (
    //           appointment.accepted == "0" &&
    //           appointment.sentByProvider == "1"
    //         ) {
    //           let newAppointment = {
    //             name: appointment.description,
    //             start: new Date(appointment.rawDate),
    //             end: new Date(appointment.rawDateEnd),
    //             color: "blue",
    //             timed: true,
    //             appointment: appointment,
    //             project: project,
    //           };
    //           array.push(newAppointment);
    //         }
    //       });
    //     }
    //   }
    //   return array;
    // },
  },
  data() {
    return {
      tab: null,
      sidebarColorModel: this.sidebarColor,
      isActive: false,
      navbarFixedModel: this.navbarFixed,
    };
  },
  methods: {
    removeNotification(index) {
      if (this.notifications.length > 0) {
        this.notifications.splice(index, 1);
        this.$store.commit("notifications", this.notifications);
      }
    },
    active: function () {
      this.isActive = !this.isActive;
    },
    editAppointment(appointment) {
      this.$store.commit("loadingCustomers", true);
      appointment.accepted = true;
      axiosAuth
        .patch("/appointments/" + appointment.id, appointment)
        .then((res) => {
          this.$store.commit("loadingAppointments", false);
          if (this.selectedType == "provider") {
            this.$store.dispatch("getProjects", this.selectedCompany.id);
          } else {
            this.$store.dispatch("getSelectedProject", this.selectedProject.id);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingAppointments", false);
          if (this.selectedType == "provider") {
            this.$store.dispatch("getProjects", this.selectedCompany.id);
          } else {
            this.$store.dispatch("getSelectedProject", this.selectedProject.id);
          }

          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Der Termin konnte nicht abgelehnt werden.",
            "error"
          );
        });
    },

    deleteAppointment(appointment) {
      this.$store.commit("loadingCustomers", true);
      appointment.accepted = false;
      axiosAuth
        .patch("/appointments/" + appointment.id, appointment)
        .then((res) => {
          this.$store.commit("loadingAppointments", false);
          if (this.selectedType == "provider") {
            this.$store.dispatch("getProjects", this.selectedCompany.id);
          } else {
            this.$store.dispatch("getSelectedProject", this.selectedProject.id);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingAppointments", false);
          if (this.selectedType == "provider") {
            this.$store.dispatch("getProjects", this.selectedCompany.id);
          } else {
            this.$store.dispatch("getSelectedProject", this.selectedProject.id);
          }

          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Der Termin konnte nicht abgelehnt werden.",
            "error"
          );
        });
    },
    editCustomer(customer) {
      this.$store.commit("loadingCustomers", true);
      axiosAuth
        .patch("/customers/" + customer.id, { accepted: true })
        .then((res) => {
          this.$store.commit("loadingAppointments", false);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingAppointments", false);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);

          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Der Termin konnte nicht abgelehnt werden.",
            "error"
          );
        });
    },
    deleteCustomer(customer) {
      this.$store.commit("loadingCustomers", true);
      axiosAuth
        .patch("/customers/" + customer.id, { accepted: false })
        .then((res) => {
          this.$store.commit("loadingAppointments", false);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingAppointments", false);
          this.$store.dispatch("getSelectedCompany", this.selectedCompany.id);

          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Der Termin konnte nicht abgelehnt werden.",
            "error"
          );
        });
    },
  },
};
</script>
