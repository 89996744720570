<template>
  <v-card class="card-shadow border-radius-xl">
    <v-toolbar elevation="0" class="px-5">
      <v-spacer></v-spacer>
      <v-btn icon @click="$store.commit('activateAccountPopup', false)">
        <v-icon class="text-secondary">fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="card-padding pb-4 white">
      <div>
        <h4 class="text-h2 mt-0 mt-md-10 font-weight-bolder text-typo mb-2">
          Bitte bestätigen Sie Ihre E-Mail-Adresse.
        </h4>
        <p class="mb-0 text-typo">
          Wir haben Ihnen einen Code per E-Mail gesendet, bitte geben sie ihn
          hier ein:
        </p>
      </div>
      <p class="text-danger">
        {{ authMessage.message }}
      </p>
      <v-text-field
        placeholder="6-Stelliger Code"
        v-model="form.verificationCode"
        type="number"
        :rules="min6"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
      ></v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        :disabled="form.verificationCode.length != 6"
        class="font-weight-bold text-uppercase btn-default py-2 px-6 me-2 mt-6 mb-2 w-100"
        :class="form.verificationCode.length != 6 ? '' : 'bg-warning'"
        color="#5e72e4"
        small
        @click="verifyEmail"
        ><span v-if="!updatingAuth">Code bestätigen</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase btn-default bg-warning py-2 px-6 me-2 mt-6 mb-2 w-100"
        color="#5e72e4"
        small
        :disabled="codeSent"
        @click="
          $store.dispatch('resendCode', {
            email: userInformations.email,
            resendActivation: true,
          })
        "
        ><span v-if="!updatingAuth"
          >Neue{{ codeSent ? "r" : "n" }} Code
          {{ codeSent ? "wurde gesendet" : "senden" }}</span
        >
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>
      <p class="text-center">{{ userInformations.email }}</p>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Bitte gebe eine gültige E-Mail-Adresse an",
      ],
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      loginDialog: false,
      registerDialog: false,
      ratingDialog: false,
      suggestionDialog: false,
      dialog: false,
      form: {
        verificationCode: "",
        password: "",
      },
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    accountCompleted() {
      return this.$store.getters.accountCompleted;
    },
    codeSent() {
      return this.$store.getters.codeSent;
    },
    accountCreated() {
      return this.$store.getters.accountCreated;
    },
    authMessage() {
      return this.$store.getters.authMessage;
    },
    updatingAuth() {
      return this.$store.getters.updatingAuth;
    },
    validateSymbol() {
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(this.form.password)) {
        return true;
      } else {
        return false;
      }
    },
    validateLength() {
      return this.form.password.length >= 8;
    },
    validateNumber() {
      return /\d/.test(this.form.password);
    },
  },
  methods: {
    verifyEmail() {
      let payload = {
        code: this.form.verificationCode,
        email: this.userInformations.email,
      };
      this.$store.dispatch("verifyEmail", payload);
    },
  },
};
</script>

<style>
</style>