<template>
  <v-navigation-drawer
    v-if="
      (selectedType == 'provider' && selectedCompany) ||
      (selectedType == 'customer' && selectedProject)
    "
    width="100%"
    height="calc(100%)"
    fixed
    :touchless="true"
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class=""
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0" :to="'/dashboard'">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pl-2 py-5 d-flex align-center">
            <v-img
              src="@/assets/img/soap.svg"
              class="navbar-brand-img pa-1 ms-3 mr-2"
              width="55"
              height="55"
              contain
            >
            </v-img>
            <span class="font-weight-medium text-h5">soap</span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr
      class="horizontal mb-0"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <h5
      class="text-uppercase text-truncate overflow-x-auto text-start text-h6 ls-0 font-weight-bolder p-0 mx-4 mt-4 ps-2 d-none-mini white-space-nowrap"
      :class="sidebarTheme == 'dark' ? 'text-white' : 'text-default'"
    >
      Überblick
    </h5>
    <v-list nav dense class="pa-0 rounded-0 mt-2">
      <div v-for="item in itemsOverview" :key="item.title">
        <v-list-group
          v-if="item.items"
          :ripple="false"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="pb-1 rounded-0"
          active-class="bg-warning"
        >
          <template v-slot:activator>
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img
                :src="item.src"
                width="55"
                height="55"
                class="font-weight-light"
                contain
              >
              </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0 rounded-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <div class="w-100 d-flex align-center pa-2">
              <span class="v-list-item-mini" v-text="child.prefix"></span>

              <v-list-item-content
                class="ms-6 ps-1 text-typo"
                v-if="!child.items"
              >
                <v-list-item-title @click="listClose($event)">{{
                  child.title
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                <v-list-group
                  class="rounded-0"
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="fas fa-angle-down me-auto ms-1"
                        active-class="bg-warning white-text"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="py-0">
                            <v-list-item-title>{{
                              child.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span
                      class="v-list-item-mini"
                      v-text="child2.prefix"
                    ></span>
                    <v-list-item-content>
                      <v-list-item-title>{{ child2.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
        <div v-else>
          <v-list-item
            link
            :to="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="!item.external"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img
                :src="item.src"
                width="55"
                height="55"
                contain
                class="font-weight-normal"
              >
              </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :href="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="item.external"
            target="_blank"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-list>

    <hr
      class="horizontal mb-0"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <h5
      class="text-uppercase text-truncate overflow-x-auto text-h6 text-start ls-0 font-weight-bolder p-0 mx-4 mt-4 my-3 ps-2 d-none-mini white-space-nowrap"
      :class="sidebarTheme == 'dark' ? 'text-white' : 'text-default'"
    >
      Arbeitsbereich
    </h5>

    <v-list nav dense class="pa-0 rounded-0">
      <div v-for="item in items" :key="item.title">
        <v-list-group
          v-if="item.items"
          :ripple="false"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="pb-1 rounded-0"
          active-class="bg-warning white-text"
        >
          <template v-slot:activator>
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0 rounded-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <div class="w-100 d-flex align-center pa-2">
              <span class="v-list-item-mini ml-8" v-text="child.prefix"></span>

              <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                <v-list-item-title @click="listClose($event)">{{
                  child.title
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                <v-list-group
                  class="rounded-0"
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="fas fa-angle-down me-auto ms-1"
                        active-class="bg-warning white-text"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="py-0">
                            <v-list-item-title>{{
                              child.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span
                      class="v-list-item-mini"
                      v-text="child2.prefix"
                    ></span>
                    <v-list-item-content>
                      <v-list-item-title>{{ child2.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
        <div v-else>
          <v-list-item
            link
            :to="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="!item.external"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :href="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="item.external"
            target="_blank"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-list>

    <hr
      class="horizontal my-4"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <h5
      class="text-uppercase text-truncate overflow-x-auto text-h6 text-start ls-0 font-weight-bolder p-0 mx-4 mt-4 my-3 ps-2 d-none-mini white-space-nowrap"
      :class="sidebarTheme == 'dark' ? 'text-white' : 'text-default'"
    >
      Mehr
    </h5>

    <v-list nav dense class="pa-0 rounded-0">
      <div v-for="item in itemsMore" :key="item.title">
        <v-list-group
          v-if="item.items"
          :ripple="false"
          v-model="item.active"
          append-icon="fas fa-angle-down"
          class="pb-1 rounded-0"
          active-class="bg-warning white-text"
        >
          <template v-slot:activator>
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover px-0 rounded-0"
            :class="child.items ? 'has-children' : ''"
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
          >
            <div class="w-100 d-flex align-center pa-2">
              <span class="v-list-item-mini" v-text="child.prefix"></span>

              <v-list-item-content class="ms-6 ps-1" v-if="!child.items">
                <v-list-item-title @click="listClose($event)">{{
                  child.title
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ms-6 ps-1 py-0" v-if="child.items">
                <v-list-group
                  class="rounded-0"
                  prepend-icon=""
                  :ripple="false"
                  sub-group
                  no-action
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list nav dense class="pa-0">
                      <v-list-group
                        :ripple="false"
                        append-icon="fas fa-angle-down me-auto ms-1"
                        active-class="bg-warning white-text"
                        class="mb-0"
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="py-0">
                            <v-list-item-title>{{
                              child.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-group>
                    </v-list>
                  </template>

                  <v-list-item
                    v-for="child2 in child.items"
                    :ripple="false"
                    :key="child2.title"
                    :to="child2.link"
                    @click="listClose($event)"
                  >
                    <span
                      class="v-list-item-mini"
                      v-text="child2.prefix"
                    ></span>
                    <v-list-item-content>
                      <v-list-item-title>{{ child2.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
        <div v-else>
          <v-list-item
            link
            :to="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="!item.external"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :href="item.link"
            class="no-default-hover rounded-0"
            :ripple="false"
            active-class="bg-warning white-text"
            v-if="item.external"
            target="_blank"
          >
            <v-list-item-icon class="me-2 pa-1 ml-4 align-center">
              <v-img :src="item.src" width="55" height="55" contain> </v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
    </v-list>

    <v-card
      class="pa-0 mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "warning",
    },
    sidebarTheme: {
      type: String,
      default: "white",
    },
  },
  computed: {
    itemsMore() {
      if (this.selectedType == "provider") {
        return [
          {
            action: "fas fa-cog",
            src: require("@/assets/icons/soap-settings-icon.svg"),
            link: "/settings",
            title: "Einstellungen",
            external: false,
          },
          {
            action: "fas fa-credit-card",
            src: require("@/assets/icons/soap-subscription-icon.svg"),
            link: "/subscription",
            title: "Abonnement",
            external: false,
          },
          {
            action: "fas fa-bars",
            src: require("@/assets/icons/soap-logout-icon.svg"),
            link: "/logout",
            title: "Ausloggen",
            function: "logoutModal",
            external: false,
          },
        ];
      } else {
        return [
          {
            action: "fas fa-cog",
            src: require("@/assets/icons/soap-settings-icon.svg"),
            link: "/settings",
            title: "Einstellungen",
            external: false,
          },
          {
            action: "fas fa-bars",
            src: require("@/assets/icons/soap-logout-icon.svg"),
            link: "/logout",
            title: "Ausloggen",
            function: "logoutModal",
            external: false,
          },
        ];
      }
    },
    items() {
      if (this.selectedType == "provider") {
        return [
          {
            action: "AppointmentIcon",
            src: require("@/assets/icons/soap-projects-icon.svg"),
            link: "/projects",
            title: "Projekte",
            external: false,
          },
          {
            action: "far fa-calendar",
            src: require("@/assets/icons/soap-calender-icon.svg"),
            link: "/calendar",
            title: "Kalender",
            external: false,
          },
          {
            action: "far fa-comment",
            src: require("@/assets/icons/soap-chats-icon.svg"),
            link: "/chats",
            title: "Chats",
            external: false,
          },
          {
            action: "fas fa-check",
            src: require("@/assets/icons/soap-tasks-icon.svg"),
            link: "/tasks",
            title: "Aufgaben",
            external: false,
          },
          // TO-DO
          // {
          //   action: "fas fa-at",
          //   link: "/mail",
          //   title: "E-Mail",
          //   external: false,
          // },
          {
            action: "far fa-envelope-open",
            src: require("@/assets/icons/soap-clients-icon.svg"),
            link: "/customers",
            title: "Kunden",
            external: false,
          },
          {
            action: "far fa-user",
            src: require("@/assets/icons/soap-profile-icon.svg"),
            link: "/profile",
            title: "Profil",
            external: false,
          },
        ];
      } else {
        return [
          {
            action: "far fa-calendar",
            src: require("@/assets/icons/soap-calender-icon.svg"),
            link: "/calendar",
            title: "Kalender",
            external: false,
          },
          {
            action: "far fa-comment",
            src: require("@/assets/icons/soap-chats-icon.svg"),
            link: "/chats",
            title: "Chats",
            external: false,
          },
          // TO-DO
          // {
          //   action: "fas fa-at",
          //   link: "/mail",
          //   title: "E-Mail",
          //   external: false,
          // },

          {
            action: "far fa-user",
            src: require("@/assets/icons/soap-profile-icon.svg"),
            link: "/profile",
            title: "Profil",
            external: false,
          },
        ];
      }
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    userInfo: [
      {
        title: "My Profile",
        prefix: "MP",
      },
      {
        title: "Settings",
        prefix: "S",
      },
      {
        title: "Logout",
        prefix: "L",
      },
    ],
    itemsOverview: [
      {
        action: "fas fa-chart-line",
        src: require("@/assets/icons/soap-dashboard-icon.svg"),
        link: "/dashboard",
        title: "Dashboard",
        external: false,
      },
    ],
  }),
  methods: {
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
<style >
.v-list-item__title {
  font-size: 16px !important;
}
</style>