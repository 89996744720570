<template>
  <div v-if="user && company">
    <v-card
      @click="$emit('setUser', user)"
      class="border-radius-md bg-light ma-2 pa-2"
    >
      <div>
        <v-row class="align-center">
          <v-avatar
            size="50"
            class="ma-3 text-uppercase text-dark"
            color="#F3F5F6"
          >
            <v-img
              :src="getImage(user.avatar)"
              :alt="user.firstname"
              v-on:error="onImgError"
            />
          </v-avatar>
          <v-col>
            <div class="font-weight-bolder text-md">
              {{ `${user.firstname || ""} ${user.lastname || ""}` }}
            </div>
            <div class="font-weight-normal text-md">
              {{ `${company.name || ""} ${company.legalForm || ""}` }}
            </div>
          </v-col>
          <v-icon class="text-secondary mr-5">fas fa-chevron-right</v-icon>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    user: null,
    company: null,
  },
};
</script>

<style>
</style>