<template>
  <v-card class="card-shadow border-radius-xl pb-4" v-if="selectedTask">
    <v-window v-model="tab">
      <v-window-item value="start">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="selectTask(null)">
            <v-icon size="15" class="">fas fa-times</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="selectedType == 'provider'"
            :key="selectedTask.status"
            :class="
              selectedTask.status == '1'
                ? 'bg-success text-white'
                : 'bg-light text-secondary'
            "
            elevation="0"
            @click="setStatus()"
          >
            <v-icon size="15" class="">fas fa-check</v-icon>
          </v-btn>

          <v-btn
            v-if="selectedType == 'provider'"
            class="bg-danger text-white ml-2"
            elevation="0"
            @click="showWarningAlert()"
          >
            <v-icon size="15" class="">fas fa-trash</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-3">
          <div>
            <span class="font-weight-bolder">{{ selectedTask.title }}</span>
          </div>
          <div>
            <span style="color: #edd680"> Projekt: </span>
            {{
              findProjectById ? findProjectById.title : selectedProject.title
            }}
          </div>
        </div>
        <v-divider></v-divider>
        <v-list class="pa-0">
          <v-list-item
            @click="tab = 'colors'"
            :disabled="selectedType != 'provider'"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Farbe</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div
                class="mr-3 pt-1 secondary--text text-md"
                :style="`color: #${
                  getColorByColorCode(selectedTask.color).colorCode
                }`"
              >
                {{ getColorByColorCode(selectedTask.color).colorName }}
              </div>
              <div v-if="selectedType == 'provider'">
                <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="tab = 'deadline'"
            :disabled="selectedType != 'provider'"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Deadline</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                {{ selectedTask.deadline | moment("DD.MM.YYYY - HH:mm") }}
              </div>
              <div v-if="selectedType == 'provider'">
                <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="tab = 'manager'"
            :disabled="selectedType != 'provider'"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Verantwortlich</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="selectedTask.responsible">
                  {{
                    `${selectedTask.responsible.firstname || ""} ${
                      selectedTask.responsible.lastname || ""
                    }`
                  }}</span
                >
              </div>
              <div v-if="selectedType == 'provider'">
                <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
        <v-form @submit.prevent lazy-validation>
          <v-textarea
            :disabled="selectedType != 'provider'"
            placeholder="Notiz hinzufügen..."
            class="rounded-lg mt-5 mb-3 mx-3"
            v-model="selectedTask.description"
            filled
            rounded
            hide-details=""
            dense
          ></v-textarea>
        </v-form>
        <div class="px-5 text-center mt-5">
          Hinzugefügt von:
          <span class="font-weight-bolder">
            {{
              `${selectedTask.creator.firstname || ""} ${
                selectedTask.creator.lastname || ""
              }`
            }}
          </span>
          <div class="text-grey-5">
            {{ selectedTask.createdAt | moment("DD.MM.YYYY - HH:mm") }} Uhr
          </div>
        </div>
        <v-card-actions class="mx-2" v-if="selectedType == 'provider'">
          <v-btn
            block
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
            small
            @click="updateNote"
            ><span v-if="!loadingCompanies">Speichern</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular
          ></v-btn>
        </v-card-actions>
      </v-window-item>
      <v-window-item value="colors">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Farben</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pa-0">
          <div v-for="(color, index) in colors" :key="color + index">
            <v-list-item @click="selectColor(color)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md">
                  <v-avatar
                    :style="`backgroundColor: #${
                      getColorByColorCode(color.colorCode).colorCode
                    }`"
                    size="25"
                    class="mr-3"
                  ></v-avatar
                  >{{ color.colorName }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-window-item>
      <v-window-item value="deadline">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Deadline</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="mt-4 text-center mx-5">
          <v-datetime-picker
            label="Deadline wählen"
            v-model="selectedTask.deadline"
            :okText="'Ok'"
            :clearText="'Löschen'"
            :dateIcon="null"
            :timePickerProps="{ format: '24hr' }"
          >
          </v-datetime-picker>
        </div>
      </v-window-item>
      <v-window-item value="manager">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Teammitglieder</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>

        <Teammember
          :user="userInformations"
          :company="selectedCompany"
          @setUser="setUser"
        />

        <Teammember
          v-for="(teammember, index) in selectedCompany.members"
          :key="index"
          :user="teammember.member"
          :company="teammember.company"
          @setUser="setUser"
        />
      </v-window-item>
    </v-window>
  </v-card>
</template>
    
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import Teammember from "../Cards/Teammember.vue";
import { format } from "d3";
export default {
  name: "TaskDetailModal",
  data() {
    return {
      tab: null,
      timerId: null,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  components: { Teammember },
  methods: {
    showWarningAlert() {
      this.$swal({
        title: "Bist du dir sicher?",
        text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$swal.fire(
            "Gelöscht!",
            "Die Aufgabe wurde gelöscht.",
            "success"
          );
          this.selectedTask.companyId = this.selectedCompany.id;
          this.$store.dispatch("deleteTask", this.selectedTask);
          this.selectTask(null);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },

    setUser(value) {
      this.selectedTask.responsible = value;
      this.selectedTask.responsibleId = value.id;
      this.tab = "start";
      this.selectedTask.companyId = this.selectedCompany.id;
      this.$store.dispatch("updateTask", this.selectedTask);
    },
    setStatus() {
      if (
        this.selectedTask.status == "1" ||
        this.selectedTask.status === true
      ) {
        this.selectedTask.status = "0";
      } else {
        this.selectedTask.status = "1";
      }

      this.selectedTask.companyId = this.selectedCompany.id;
    },
    selectColor(color) {
      this.selectedTask.color = color.colorCode;
      this.tab = "start";
      this.selectedTask.companyId = this.selectedCompany.id;
    },
    updateNoteDelayed() {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.selectedTask.companyId = this.selectedCompany.id;
        this.$store.dispatch("updateTask", this.selectedTask);
      }, 1000);
    },
    updateNote() {
      this.selectedTask.companyId = this.selectedCompany.id;
      this.$store.dispatch("updateTask", this.selectedTask);
      this.$swal.fire(
        "Aktualisiert!",
        "Die Aufgabe wurde gespeichert",
        "success"
      );
    },
  },
  computed: {},
};
</script>
<style>
.v-time-picker-title {
  color: black !important;
}

.v-picker__title {
  color: black !important;
}

.v-date-picker-table .v-btn.v-btn--active {
  color: orange !important;
}

.v-time-picker-clock__hand {
  background-color: black !important;
}

.v-time-picker-clock__item--active {
  color: orange !important;
}
</style>