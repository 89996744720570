/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import { store } from '@/store'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import coreData from "@/mixins/coreDataMixin.js";
import Sortable from 'vue-sortable'

Vue.use(Sortable)
Vue.use(require('vue-moment'));

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUser } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUser)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import CompanyDetailModal from "./components/Modals/Company.vue"
Vue.component('company-detail-modal', CompanyDetailModal)

import LogoutModal from "./components/Modals/Logout.vue"
Vue.component('logout-modal', LogoutModal)

import NewProjectModal from "./components/Modals/NewProject.vue"
Vue.component('new-project-modal', NewProjectModal)

import EditProjectModal from "./components/Modals/EditProject.vue"
Vue.component('edit-project-modal', EditProjectModal)

import AddCustomerModal from "./components/Modals/AddCustomer.vue"
Vue.component('add-customer-modal', AddCustomerModal)

import AddAppointmentModal from "./components/Modals/NewAppointment.vue"
Vue.component('add-appointment-modal', AddAppointmentModal)

import AddTeammemberModal from "./components/Modals/AddTeammember.vue"
Vue.component('add-teammember-modal', AddTeammemberModal)

import TaskDetailModal from "./components/Modals/Task.vue"
Vue.component('task-detail-modal', TaskDetailModal)

import BaseIcon from "../src/components/BaseIcon.vue"
Vue.component('BaseIcon', BaseIcon)

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

import AppointmentIcon from '@/assets/icons/soap-appointmentsAndNotifications-icon.svg'
Vue.use(AppointmentIcon)

Vue.config.productionTip = false;

// import firebaseMessaging from './firebase-config'

// Vue.prototype.$messaging = firebaseMessaging

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);
Vue.mixin(coreData)

router.beforeEach((to, from, next) => {

  if ((!localStorage.getItem('token')) && to.path !== `/login` && to.path !== `/signup` && to.path !== `/resetpassword`) {
    return next(`/login`)
  } else {
    return next()
  }

})

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
