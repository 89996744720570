import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        tasks: [],
        loadingTasks: false,
        selectedTask: null,
        taskDetailModal: false
    }
}

const state = getDefaultState()

const getters = {
    tasks: state => {
        return state.tasks
    },

    selectedTask: state => {
        return state.selectedTask
    },

    loadingTasks: state => {
        return state.loadingTasks
    },

    taskDetailModal: state => {
        return state.taskDetailModal
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    tasks: (state, payload) => {
        state.tasks = payload
    },
    selectedTask: (state, payload) => {
        state.selectedTask = payload
    },
    loadingTasks: (state, payload) => {
        state.loadingTasks = payload
    },
    taskDetailModal: (state, payload) => {
        state.taskDetailModal = payload
    },

}

const actions = {

    getTasks: ({ commit }) => {
        commit('loadingTasks', true)
        axiosAuth.get('/tasks?$limit=100', {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingTasks', false)
                    if (!res.data.message) {
                        commit('tasks', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingTasks', false)
                    commit('companies', [])

                }
            )
    },

    postTask: ({ commit, dispatch }, payload) => {
        commit('loadingTasks', true)
        axiosAuth.post('/tasks', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {

                    commit('loadingTasks', false)
                    dispatch('getProjects', payload.companyId)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingTasks', false)

                }
            )
    },

    updateTask: ({ commit, dispatch }, payload) => {

        commit('loadingTasks', true)
        axiosAuth.patch('/tasks/' + payload.id, payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {

                    commit('loadingTasks', false)
                    dispatch('getProjects', payload.companyId)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingTasks', false)

                }
            )
    },


    deleteTask: ({ commit, dispatch }, payload) => {
        commit('loadingTasks', true)
        axiosAuth.delete('/tasks/' + payload.id, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingTasks', false)
                    dispatch('getProjects', payload.companyId)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingTasks', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}