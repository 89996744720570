<template>
  <div>
    <v-card
      class="card-shadow border-radius-xl pb-4"
      v-if="selectedCompany.creatorId == userInformations.id"
    >
      <v-window v-model="tab">
        <v-window-item value="start"
          ><v-toolbar elevation="0">
            <v-btn
              class="bg-light"
              elevation="0"
              @click="
                $store.dispatch('getSelectedCompany', selectedCompany.id);
                closeCompanyInfoModal();
              "
            >
              <v-icon size="15" class="text-secondary"
                >fas fa-chevron-left</v-icon
              >
            </v-btn>

            <v-spacer></v-spacer>
            <v-toolbar-title>
              <div class="font-weight-bolder text-xl">Meine Firma</div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div style="width: 64px"></div>
          </v-toolbar>

          <div class="white">
            <v-row class="justify-center ma-0 pa-0 mb-10">
              <div>
                <v-img
                  src="@/assets/img/soap.png"
                  class=""
                  width="150px"
                  height="auto"
                  contain
                >
                </v-img>
              </div>
            </v-row>
          </div>

          <v-divider></v-divider>
          <v-row class="align-center pa-0 ma-0 px-2">
            <v-avatar
              size="50"
              class="ma-3 text-uppercase text-dark"
              color="#F3F5F6"
            >
              <v-img
                :src="getImage(userInformations.avatar)"
                :alt="userInformations.firstname"
                v-on:error="onImgError"
              />
            </v-avatar>
            <v-col>
              <div class="my-1 font-weight-bolder text-md">Firmendaten</div>
              <div class="my-1 font-weight-normal text-md">
                <p class="mb-0 text-typo">
                  Erzähle uns mehr über dein Business
                </p>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-form
            v-model="validationState"
            ref="registerCompanyForm"
            @submit.prevent
          >
            <v-list class="pa-0">
              <v-list-item-group color="primary">
                <v-list-item @click="tab = 'legalform'">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bolder text-md"
                      >Rechtsform</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <div class="mr-3 pt-1 secondary--text text-md">
                      {{ selectedCompany.legalForm }}
                    </div>
                    <div>
                      <v-icon class="text-secondary"
                        >fas fa-chevron-right</v-icon
                      >
                    </div>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <v-list class="pa-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bolder text-md"
                    >Umsatzsteuerbefreit</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon class="mt-1">
                  <v-switch
                    class="mt-2"
                    v-model="selectedCompany.salesTaxExemption"
                    hide-details
                    inset
                    color="green"
                  ></v-switch>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <div v-if="!selectedCompany.salesTaxExemption">
              <div class="mx-4 mt-3">
                <div>
                  <span class="font-weight-bolder">Umsatzsteuer-ID *</span>
                </div>
                <v-text-field
                  :required="!selectedCompany.salesTaxExemption"
                  placeholder="DE 123456789"
                  class="rounded-lg mt-1"
                  :rules="formRules.vatRules"
                  v-model="selectedCompany.vat"
                  filled
                  rounded
                  dense
                ></v-text-field>
              </div>
              <div class="mx-4 mt-3">
                <div>
                  <span class="font-weight-bolder">Umsatzsteuer in % *</span>
                </div>
                <v-text-field
                  placeholder="19"
                  class="rounded-lg mt-1"
                  v-model="selectedCompany.vatValue"
                  type="number"
                  filled
                  rounded
                  dense
                  step="1"
                  min="0"
                  max="50"
                ></v-text-field>
              </div>
            </div>

            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder"
                  >Firmenname (ohne Rechtsform)*</span
                >
              </div>
              <v-text-field
                placeholder="Muster GmbH"
                class="rounded-lg mt-1"
                v-model="selectedCompany.name"
                filled
                rounded
                dense
                :rules="formRules.companyNameRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Straße*</span>
              </div>
              <v-text-field
                placeholder="Musterstraße 1"
                class="rounded-lg mt-1"
                v-model="selectedCompany.street"
                filled
                rounded
                dense
                :rules="formRules.streetRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Postleitzahl*</span>
              </div>
              <v-text-field
                placeholder="12345"
                class="rounded-lg mt-1"
                v-model="selectedCompany.zip"
                filled
                rounded
                dense
                :rules="formRules.zipRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Ort*</span>
              </div>
              <v-text-field
                placeholder="Musterstadt"
                class="rounded-lg mt-1"
                v-model="selectedCompany.city"
                filled
                rounded
                dense
                :rules="formRules.cityRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">E-Mail</span>
              </div>
              <v-text-field
                placeholder="Musterstraße 1"
                class="rounded-lg mt-1"
                v-model="selectedCompany.email"
                filled
                rounded
                dense
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Telefon</span>
              </div>
              <v-text-field
                placeholder="0123-4567890"
                class="rounded-lg mt-1"
                v-model="selectedCompany.phone"
                filled
                rounded
                dense
              ></v-text-field>
            </div>
            <v-divider></v-divider>

            <v-list-item style="min-height: 60px">
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bolder text-md text-warning"
                  >Teammitglieder</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md"></div>
              </v-list-item-icon>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item
              style="min-height: 60px"
              @click="openUserInfoModal(selectedCompany.creator)"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >{{ selectedCompany.creator.firstname }}
                  {{ selectedCompany.creator.lastname }}
                  (Chef)</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>

            <div
              v-for="teammember in selectedCompany.members"
              :key="teammember.id"
            >
              <v-divider></v-divider>
              <v-list-item
                style="min-height: 60px"
                @click="openUserInfoModal(teammember.member)"
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bolder text-md"
                    >{{ teammember.member.firstname }}
                    {{ teammember.member.lastname }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <div>
                    <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                  </div>
                </v-list-item-icon>
              </v-list-item>
            </div>
          </v-form>
          <v-card-actions class="mx-2">
            <v-btn
              block
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
              small
              @click="submitForm"
              ><span v-if="!loadingCompanies">Speichern</span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular
            ></v-btn> </v-card-actions
        ></v-window-item>
        <v-window-item value="legalform"
          ><v-toolbar elevation="0">
            <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
              <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-toolbar-title>
              <div class="font-weight-bolder text-xl">Rechtsform</div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div style="width: 64px"></div>
          </v-toolbar>
          <v-list class="pa-0">
            <div
              v-for="(legalform, index) in legalforms"
              :key="legalform + index"
            >
              <v-list-item
                @click="
                  selectedCompany.legalForm = legalform.title;
                  tab = 'start';
                "
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bolder text-md">{{
                    legalform.title
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <div>
                    <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                  </div>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </div> </v-list
        ></v-window-item>
      </v-window>
    </v-card>
    <v-card class="card-shadow border-radius-xl" v-else>
      <v-toolbar elevation="0">
        <v-btn
          class="bg-light"
          elevation="0"
          @click="
            $store.dispatch('getSelectedCompany', selectedCompany.id);
            closeCompanyInfoModal();
          "
        >
          <v-icon size="15" class="text-secondary">fas fa-chevron-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Meine Firma</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div style="width: 64px"></div>
      </v-toolbar>

      <!-- <div class="white">
        <v-row class="justify-center ma-0 pa-0 mb-10">
          <div>
            <v-img
              src="@/assets/img/soap.png"
              class=""
              width="150px"
              height="auto"
              contain
            >
            </v-img>
          </div>
        </v-row>
      </div>

      <v-divider></v-divider> -->
      <v-divider></v-divider>
      <v-row class="align-center pa-0 ma-0 px-2">
        <v-avatar
          size="50"
          class="ma-3 text-uppercase text-dark"
          color="#F3F5F6"
        >
          {{ selectedCompany.name[0] }} {{ selectedCompany.name[1] }}
        </v-avatar>
        <v-col>
          <div class="my-1 font-weight-bolder text-md">Firmendaten</div>
          <div class="my-1 font-weight-normal text-md">
            <p class="mb-0 text-typo">Ein tolles Team habt Ihr!</p>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >Firma</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span
              >{{ selectedCompany.name }} {{ selectedCompany.legalForm }}
            </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >Adresse</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span>{{ selectedCompany.street }} </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >Postleitzahl</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span>{{ selectedCompany.zip }} </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >Ort</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span>{{ selectedCompany.city }} </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >E-Mail</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span>{{ selectedCompany.email }} </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >Telefon</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md">
            <span>{{ selectedCompany.phone }} </span>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item style="min-height: 60px">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md text-warning"
            >Teammitglieder</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div class="mr-3 pt-1 secondary--text text-md"></div>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        style="min-height: 60px"
        @click="openUserInfoModal(selectedCompany.creator)"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >{{ selectedCompany.creator.firstname }}
            {{ selectedCompany.creator.lastname }} (Chef)</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div>
            <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
          </div>
        </v-list-item-icon>
      </v-list-item>
      <div v-for="teammember in selectedCompany.members" :key="teammember.id">
        <v-divider></v-divider>
        <v-list-item
          style="min-height: 60px"
          @click="openUserInfoModal(teammember.member)"
        >
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >{{ teammember.member.firstname }}
              {{ teammember.member.lastname }}</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div>
              <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
            </div>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-card>
  </div>
</template>
  
  <script>
export default {
  name: "CompanyDetailModal",
  data() {
    return {
      tab: null,
      items: [{ text: "Rechtsform", icon: "fas fa-chevron-right" }],
      validationState: null,
    };
  },
  methods: {
    selectType(type) {
      this.$store.commit("selectedType", type);
      this.selectCompanyModal = false;
    },
    submitForm() {
      this.$refs.registerCompanyForm.validate();
      if (this.validationState) {
        let newData = {
          id: this.selectedCompany.id,
          name: this.selectedCompany.name,
          street: this.selectedCompany.street,
          city: this.selectedCompany.city,
          zip: this.selectedCompany.zip,
          phone: this.selectedCompany.phone,
          email: this.selectedCompany.email,
          vat: this.selectedCompany.vat,
          vatValue: this.selectedCompany.vatValue,
          legalForm: this.selectedCompany.legalForm,
          salesTaxExemption: this.selectedCompany.salesTaxExemption,
        };

        this.$store.dispatch("updateCompany", newData);
      }
    },
  },
  computed: {},
};
</script>
  
  <style>
</style>