import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        appointments: [],
        addAppointmentModal: false,
        loadingAppointments: false,
    }
}

const state = getDefaultState()

const getters = {
    appointments: state => {
        return state.appointments
    },
    addAppointmentModal: state => {
        return state.addAppointmentModal
    },
    loadingAppointments: state => {
        return state.loadingAppointments
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    appointments: (state, payload) => {
        state.appointments = payload
    },
    addAppointmentModal: (state, payload) => {
        state.addAppointmentModal = payload
    },
    loadingAppointments: (state, payload) => {
        state.loadingAppointments = payload
    },

}

const actions = {

    getAppointments: ({ commit }, providerId) => {
        commit('loadingAppointments', true)
        axiosAuth.get('/appointments?providerId=' + providerId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingAppointments', false)
                    if (!res.data.message) {
                        commit('appointments', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingAppointments', false)
                    commit('appointments', [])

                }
            )
    },


}

export default {
    state,
    getters,
    mutations,
    actions
}