import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        teammembers: [],
        loadingTeammembers: false,
        addTeammemberModal: false
    }
}

const state = getDefaultState()

const getters = {
    teammembers: state => {
        return state.teammembers
    },

    addTeammemberModal: state => {
        return state.addTeammemberModal
    },

    loadingTeammembers: state => {
        return state.loadingTeammembers
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    teammembers: (state, payload) => {
        state.teammembers = payload
    },
    addTeammemberModal: (state, payload) => {
        state.addTeammemberModal = payload
    },
    loadingTeammembers: (state, payload) => {
        state.loadingTeammembers = payload
    },

}

const actions = {

    getTeammembers: ({ commit }) => {
        commit('loadingTeammembers', true)
        axiosAuth.get('/teammembers?$limit=100', {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingTeammembers', false)
                    if (!res.data.message) {
                        commit('teammembers', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingTeammembers', false)
                    commit('teammembers', [])

                }
            )
    },

    postRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/ratings', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updating', false)
                    dispatch('getGlossary')
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },


    deleteRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/ratings/' + payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    dispatch('getRatings')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}