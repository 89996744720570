<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div class="header-auth position-relative border-radius-l min-vh-90">
        <v-container class="py-0">
          <v-row>
            <v-col lg="4" md="7" class="d-flex flex-column mx-lg-0 mx-auto">
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <router-view></router-view>
              </fade-transition>
            </v-col>
            <v-col
              cols="6"
              class="d-lg-flex d-none h-100 my-auto pe-0 pt-0 position-absolute top-0 end-0 text-center justify-center flex-column"
            >
              <div
                class="position-relative bg-default h-100 ma-4 px-16 border-radius-lg d-flex flex-column justify-center"
              >
                <v-img
                  src="@/assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines"
                  class="position-absolute opacity-4 start-0 h-100 w-100"
                >
                </v-img>
                <div class="position-relative">
                  <v-img
                    src="@/assets/img/soap.png"
                    alt="rocket"
                    max-width="500"
                    class="w-100 position-relative z-index-2 mx-auto"
                  >
                  </v-img>
                </div>
                <h3 class="text-h3 mt-10 text-white font-weight-bolder mb-2">
                  Willkommen bei soap
                </h3>
                <!-- <p class="text-white">Willkommen</p> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <content-footer></content-footer>
    </v-main>
    <v-dialog v-model="activateAccountPopup" max-width="500px" persistent>
      <ActivateAccount />
    </v-dialog>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ActivateAccount from "./Modals/ActivateAccount.vue";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ActivateAccount,
    ContentFooter,
  },
  data() {
    return {};
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    activateAccountPopup() {
      return this.$store.getters.activateAccountPopup;
    },
  },
  watch: {
    userInformations() {
      if (this.userInformations) {
        if (this.userInformations.isVerified)
          this.$router.push("/dashboard").catch(() => {});
      }
    },
  },
};
</script>
