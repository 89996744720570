import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        chatMessages: [],
        notifications: [],
        loadingMessages: false,
    }
}

const state = getDefaultState()

const getters = {
    chatMessages: state => {
        return state.chatMessages
    },
    notifications: state => {
        return state.notifications
    },
    loadingMessages: state => {
        return state.loadingMessages
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    chatMessages: (state, payload) => {
        state.chatMessages = payload
    },
    notifications: (state, payload) => {
        state.notifications = payload
    },
    loadingMessages: (state, payload) => {
        state.loadingMessages = payload
    },

}

const actions = {
    getMessages: ({ commit }, projectId) => {
        commit('loadingMessages', true)
        axiosAuth.get('/messages?projectId=' + projectId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingMessages', false)
                    if (!res.data.message) {
                        commit('chatMessages', res.data)
                        var div = document.getElementById("chat-list");
                        div.scrollTop = div.scrollHeight;
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingMessages', false)
                    commit('companies', [])

                }
            )
    },

    setMessagesToRead: ({ commit }, projectId) => {
        axiosAuth.patch('/messages/' + projectId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                }
            )
    },

    postMessage: ({ commit, dispatch }, payload) => {
        commit('loadingMessages', true)
        axiosAuth.post('/messages', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingMessages', false)
                    dispatch('getProjects', payload.companyId)
                    dispatch('getMessages', payload.projectId)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingMessages', false)

                }
            )
    },


    deleteRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/ratings/' + payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    dispatch('getRatings')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}