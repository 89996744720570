import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        customers: [],
        loadingCustomers: false,
        addCustomerModal: false,
        customerInfoModal: false,
        selectedCustomer: null,
    }
}

const state = getDefaultState()

const getters = {
    customers: state => {
        return state.customers
    },

    loadingCustomers: state => {
        return state.loadingCustomers
    },
    customerInfoModal: state => {
        return state.customerInfoModal
    },
    addCustomerModal: state => {
        return state.addCustomerModal
    },
    selectedCustomer: state => {
        return state.selectedCustomer
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    customers: (state, payload) => {
        state.customers = payload
    },
    loadingCustomers: (state, payload) => {
        state.loadingCustomers = payload
    },
    customerInfoModal: (state, payload) => {
        state.customerInfoModal = payload
    },
    selectedCustomer: (state, payload) => {
        state.selectedCustomer = payload
    },
    addCustomerModal: (state, payload) => {
        state.addCustomerModal = payload
    },

}

const actions = {

    getCustomers: ({ commit }, providerId) => {
        commit('loadingCustomers', true)
        axiosAuth.get('/customers?$limit=100&providerId=' + providerId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingCustomers', false)
                    if (!res.data.message) {
                        commit('customers', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingCustomers', false)
                    commit('customers', [])

                }
            )
    },

    postRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.post('/ratings', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updating', false)
                    dispatch('getGlossary')
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },


    deleteRating: ({ commit, dispatch }, payload) => {
        commit('updating', true)
        axiosAuth.delete('/ratings/' + payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    dispatch('getRatings')
                    commit('updating', false)

                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('updating', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}