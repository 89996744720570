<template>
  <v-card class="card-shadow border-radius-xl pb-4">
    <v-toolbar elevation="0">
      <v-btn class="bg-light" elevation="0" @click="closeAddCustomerModal()">
        <v-icon size="15" class="">fas fa-times</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <div class="font-weight-bolder text-xl">Kunde einladen</div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div style="width: 64px"></div>
    </v-toolbar>
    <div class="mx-4 mt-3">
      <div>
        <span class="font-weight-bolder">Firmen-ID / E-Mail-Adresse</span>
      </div>
      <v-form
        v-model="validationState"
        ref="registerCompanyForm"
        @submit.prevent
      >
        <v-text-field
          placeholder="123ABC456 / max@mustermann.de"
          class="rounded-lg mt-1"
          v-model="companyId"
          filled
          rounded
          dense
          :rules="formRules.companyIdRules"
          required
        ></v-text-field>
      </v-form>
      <div class="ma-3 text-center text-secondary">
        Gib die Firmen-ID der Firma oder die E-Mail-Adresse des Chefs ein, die
        du als Kunde hinzufügen möchtest.
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        block
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
        small
        @click="inviteCustomer"
        ><span v-if="!loadingCustomers">Einladen</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>
    
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import Teammember from "../Cards/Teammember.vue";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "AddCustomerModal",
  data() {
    return {
      tab: null,
      companyId: "",
      validationState: null,
    };
  },
  components: { Teammember },
  methods: {
    showWarningAlert() {
      this.$swal({
        title: "Möchtest du die Einladung versenden?",
        text: "",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte einladen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$swal.fire(
            "Gesendet!",
            "Die Aufgabe wurde gelöscht.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
    inviteCustomer() {
      if (this.companyId.length < 6) return;
      this.$store.commit("loadingCustomers", true);
      axiosAuth
        .post(
          "/customers",
          { providerId: this.selectedCompany.id, generatedId: this.companyId },
          {
            headers: {
              version: "v1.1",
            },
          }
        )
        .then((res) => {
          this.$store.commit("loadingCustomers", false);
          this.$store.dispatch("getCustomers", this.selectedCompany.id);
          this.closeAddCustomerModal();
          this.$swal.fire(
            "Einladung gesendet!",
            "Der Kunde wurde eingeladen.",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingCustomers", false);
          this.$store.dispatch("getCustomers", this.selectedCompany.id);
          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Die Einladung konnte nicht gesendet werden, bitte überprüfe die Firmen-ID.",
            "error"
          );
        });
    },
  },
  computed: {},
};
</script>