<template>
  <v-card class="card-shadow border-radius-xl pb-4">
    <!-- <v-window v-model="tab">
      <v-window-item value="start"> </v-window-item
    ></v-window> -->
    <div>
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="setSelectTypeModal()">
          <v-icon size="15" class="text-secondary">fas fa-chevron-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div class="white">
        <v-row class="justify-center ma-0 pa-0 mb-10">
          <div>
            <v-img
              src="@/assets/img/soap.png"
              class=""
              width="150px"
              height="auto"
              contain
            >
            </v-img>
          </div>
        </v-row>
      </div>

      <v-divider></v-divider>
      <v-row class="align-center pa-0 ma-0 px-2">
        <v-avatar
          size="50"
          class="ma-3 text-uppercase text-dark"
          color="#F3F5F6"
        >
          <v-img
            :src="getImage(userInformations.avatar)"
            :alt="userInformations.firstname"
            v-on:error="onImgError"
          />
        </v-avatar>
        <v-col>
          <div class="my-1 font-weight-bolder text-md">
            Hallo {{ userInformations.firstname }}
          </div>
          <div class="my-1 font-weight-normal text-md">
            <p class="mb-0 text-typo">Wähle Deine Firma.</p>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div class="my-1 font-weight-bolder text-h5 px-5 pt-4">Was bist du?</div>
      <v-tabs v-model="tab" background-color="transparent" grow class="px-5">
        <v-tabs-slider></v-tabs-slider>
        <v-tab :ripple="false" href="#chef">
          <span class="ms-1">Chef</span>
        </v-tab>

        <v-tab :ripple="false" href="#mitarbeiter">
          <span class="ms-1">Mitarbeiter</span>
        </v-tab>
      </v-tabs>
      <v-toolbar elevation="0" class="mx-2">
        <div class="font-weight-bolder">Firmen</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="openCompanyDetailModal()">
          <v-icon class="font-weight-bolder text-h">fas fa-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="chef">
            <div
              class="mb-2 bg-light pa-3 border-radius-lg"
              style="cursor: pointer; position: relative"
              v-for="company in companies.data"
              :key="company.id"
              @click="selectCompany(company)"
            >
              <v-row class="align-center">
                <v-avatar
                  size="50"
                  class="ma-3 text-uppercase text-dark"
                  color="grey"
                >
                  {{ company.name[0] }}{{ company.name[1] }}
                </v-avatar>
                <v-col>
                  <div>
                    <div class="text-dark">
                      {{ company.name }} {{ company.legalForm }}
                    </div>
                    <div class="text-body">
                      {{ company.street }}, {{ company.zip }} {{ company.city }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-badge
                v-if="calculateNotifications(company).length > 0"
                style="
                  background-color: red;
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  border-radius: 50px;
                "
                color="error"
                :content="calculateNotifications(company).length"
                inline
              ></v-badge>
            </div>
          </v-window-item>
          <v-window-item value="mitarbeiter">
            <div v-if="teammembers">
              <div
                class="mb-2 bg-light pa-3 border-radius-lg"
                style="cursor: pointer; position: relative"
                v-for="member in teammembers.data"
                :key="member.id"
                @click="selectCompany(member.company)"
              >
                <v-row class="align-center">
                  <v-avatar
                    size="50"
                    class="ma-3 text-uppercase text-dark"
                    color="grey"
                  >
                    {{ member.company.name[0] }}{{ member.company.name[1] }}
                  </v-avatar>
                  <v-col>
                    <div>
                      <div class="text-dark">
                        {{ member.company.name }} {{ member.company.legalForm }}
                      </div>
                      <div class="text-body">
                        {{ member.company.street }}, {{ member.company.zip }}
                        {{ member.company.city }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-badge
                  v-if="calculateNotifications(member.company).length > 0"
                  style="
                    background-color: red;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    border-radius: 50px;
                  "
                  color="error"
                  :content="calculateNotifications(member.company).length"
                  inline
                ></v-badge>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
      <div class="my-5 px-5 text-center text-body">
        Wähle deine Firma oder füge eine Neue hinzu
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return { tab: null };
  },
  methods: {
    selectType(type) {
      this.$store.commit("selectedType", type);
      // this.selectCompanyModal = false;
    },
  },
  computed: {},
};
</script>

<style>
</style>