import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        companies: [],
        selectedCompany: null,
        selectCompanyModal: false,
        companyDetailModal: false,
        companyInfoModal: false,
        loadingCompanies: false,
    }
}

const state = getDefaultState()

const getters = {
    companies: state => {
        return state.companies
    },
    selectedCompany: state => {
        return state.selectedCompany
    },
    selectCompanyModal: state => {
        return state.selectCompanyModal
    },
    companyDetailModal: state => {
        return state.companyDetailModal
    },
    companyInfoModal: state => {
        return state.companyInfoModal
    },
    loadingCompanies: state => {
        return state.loadingCompanies
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    companies: (state, payload) => {
        state.companies = payload
    },
    selectedCompany: (state, payload) => {
        state.selectedCompany = payload
        if (payload)
            localStorage.setItem('selectedCompany', JSON.stringify(payload));
    },
    selectCompanyModal: (state, payload) => {
        state.selectCompanyModal = payload
    },
    companyDetailModal: (state, payload) => {
        state.companyDetailModal = payload
    },
    companyInfoModal: (state, payload) => {
        state.companyInfoModal = payload
    },
    loadingCompanies: (state, payload) => {
        state.loadingCompanies = payload
    },

}

const actions = {

    getCompanies: ({ commit }) => {
        commit('loadingCompanies', true)
        axiosAuth.get('/companies?$limit=100', {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingCompanies', false)
                    if (!res.data.message) {
                        commit('companies', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingCompanies', false)
                    commit('companies', [])

                }
            )
    },

    getSelectedCompany: ({ commit, dispatch }, id) => {
        commit('loadingCompanies', true)
        axiosAuth.get('/companies/' + id, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingCompanies', false)
                    if (!res.data.message) {
                        commit('selectedCompany', res.data)
                        dispatch('getAppointments', res.data.id)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingCompanies', false)
                    if (error.response.status === 404 || error.response.status === 403)
                        dispatch('logout')
                }
            )
    },

    postNewCompany: ({ commit, dispatch }, payload) => {
        commit('loadingCompanies', true)
        axiosAuth.post('/companies', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingCompanies', false)
                    dispatch('getCompanies')
                    commit('companyDetailModal', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingCompanies', false)

                }
            )
    },

    updateCompany: ({ commit, dispatch }, payload) => {
        commit('loadingCompanies', true)
        axiosAuth.patch('/companies/' + payload.id, payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingCompanies', false)
                    dispatch('getCompanies')
                    commit("companyInfoModal", false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingCompanies', false)

                }
            )
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}