<template>
  <v-app-bar
    v-if="userInformations"
    :color="background"
    height="auto"
    class="py-2 px-0 toolbar-content-padding-y-none"
    :class="'position-sticky bg-white z-index-sticky top-1'"
    flat
  >
    <v-row
      class="py-1"
      style="padding-bottom: 6px !important; padding-top: 6px !important"
    >
      <v-col cols="12" md="7" class="d-flex">
        <div
          v-if="!$vuetify.breakpoint.mobile"
          class="drawer-toggler pr-5 pt-5 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler px-0 pr-3 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
        <div>
          <v-avatar size="50" class="text-uppercase text-dark" color="#F3F5F6">
            <v-img
              :src="getImage(userInformations.avatar)"
              :alt="userInformations.firstname"
              v-on:error="onImgError"
            />
          </v-avatar>
        </div>
        <div class="ml-5">
          <div class="font-weight-bolder text-typo">
            Hallo {{ userInformations.firstname }}
          </div>
          <v-row class="pa-0 ma-0">
            <div class="text-s">Firma:</div>
            <v-col class="pl-2 ma-0 pa-0">
              <div
                class="text-warning"
                style="
                  white-space: nowrap;
                  overflow: hidden;

                  height: 20px;
                  text-overflow: ellipsis;
                "
                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'width: 300px;'
                    : ' width: 140px;'
                "
              >
                {{ selectedCompany.name }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="12"
        sm="6"
        md="5"
        class="d-flex align-center"
        :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      >
        <div class="navbar-search d-flex navbar-search-light ms-auto">
          <v-btn
            @click="openNewAppointmentModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
            small
          >
            Termin anfragen
          </v-btn>
          <v-btn
            v-if="selectedType == 'provider'"
            @click="openNewProjectModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
            small
          >
            Projekt hinzufügen
          </v-btn>
          <v-btn
            v-else
            @click="setSelectProjectModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
            small
          >
            Projekt wählen
          </v-btn>
        </div>
        <!-- 
        <v-btn
          icon
          :ripple="false"
          :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
          class="text-body"
          :color="linkColor"
          small
          @click="$emit('toggleSettingsDrawer', true)"
        >
          <v-icon class="material-icons-round me-sm-1" size="20">
            account_circle
          </v-icon>
        </v-btn>

        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>

        <v-btn
          icon
          :ripple="false"
          :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
          class="text-body px-5"
          :color="linkColor"
          small
          @click="$emit('toggleSettingsDrawer', true)"
        >
          <v-icon class="material-icons-round" size="20"> settings </v-icon>
        </v-btn>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="300"
          max-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon class="material-icons-round" size="20">
                notifications
              </v-icon>
              <v-badge
                color="#f44335"
                content="11"
                offset-x="1"
                offset-y="0"
                class="position-absolute"
                bordered
              >
              </v-badge>
            </v-btn>
          </template>

          <v-list class="pa-3">
            <v-list-item
              v-for="(item, i) in dropdown"
              :key="i"
              class="pa-4 list-item-hover-active d-flex align-items-center py-1 my-1 border-radius-md"
            >
              <v-icon class="material-icons-round text-body" size="20">{{
                item.icon
              }}</v-icon>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6
                        class="text-sm font-weight-normal ms-2 text-typo"
                        v-html="item.title"
                      >
                        {{ item.title }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      dropdown: [
        {
          icon: "email",
          title: "Check new messages",
        },
        {
          icon: "podcasts",
          title: "Manage podcast session",
        },
        {
          icon: "shopping_cart",
          title: "Payment successfully completed",
        },
      ],
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", this.togglerActive);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
