import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthIllustrationLayout from "../views/Layout/AuthIllustrationLayout";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";

const Dashboard = () => import("../views/Overview/Dashboard.vue");
const Activities = () => import("../views/Activities/Activities.vue");
const Projects = () => import("../views/Projects/Projects.vue");
const ProjectDetails = () => import("../views/Projects/ProjectDetails.vue");
const Calendar = () => import("../views/Calendar/Calendar.vue");
const Chats = () => import("../views/Chats/Chats.vue");
const Tasks = () => import("../views/Tasks/Tasks.vue");
const Mail = () => import("../views/Mail/Mail.vue");
const Customers = () => import("../views/Customers/Customers.vue");
const Profile = () => import("../views/Profile/Profile.vue");
const Settings = () => import("../views/Settings/Settings.vue");
const Subscription = () => import("../views/Subscription/Subscription.vue");
const CustomerInvitation = () => import("../views/CustomerInvitation/CustomerInvitation.vue");
const Login = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Login.vue"
  );

const ResetPassword = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/ResetPassword.vue"
  );

const Logout = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Logout/Logout.vue"
  );

const SignUp = () =>
  import(
    /* webpackChunkName: "pages" */
    "@/views/Pages/Authentication/SignUp/Signup.vue"
  );

const NotFound = () =>
  import( /* webpackChunkName: "pages" */ "@/views/Pages/404.vue");

Vue.use(VueRouter);

let notFoundPage = {
  path: "*",
  component: DashboardLayout,
  name: "Not Found",
  children: [{
    path: "/",
    name: "404",
    component: NotFound,
  }],
};

let basePages = {
  path: "/",
  redirect: "/dashboard",
  component: AuthIllustrationLayout,
  name: "Authentication Illustration",
  children: [{
    path: "signup",
    name: "Signup",
    component: SignUp,
  }, {
    path: "login",
    name: "Login",
    component: Login,
  }, {
    path: "resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },],
};

const routes = [
  basePages,
  {
    path: "/",
    name: "Dashboard",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "activities",
        name: "Aktivitäten",
        component: Activities,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "customerinvite",
        name: "KundenEinladung",
        component: CustomerInvitation,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "projects",
        name: "Projekte",
        component: Projects,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "projects/:id",
        name: "ProjektDetails",
        component: ProjectDetails,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "calendar",
        name: "Kalender",
        component: Calendar,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "chats",
        name: "Chats",
        component: Chats,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "tasks",
        name: "Aufgaben",
        component: Tasks,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "mail",
        name: "E-Mail",
        component: Mail,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "customers",
        name: "Kunden",
        component: Customers,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "profile",
        name: "Profil",
        component: Profile,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "settings",
        name: "Einstellungen",
        component: Settings,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "subscription",
        name: "Abonnement",
        component: Subscription,
        meta: {
          groupName: "Overview",
        },
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
        meta: {
          groupName: "Logout",
        },
      },


    ],
  },
  notFoundPage,

];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
