<template>
  <v-card
    class="card-shadow border-radius-xl"
    min-height="60vh"
    style="position: relative"
  >
    <v-window v-model="tab" style="height: 100% !important">
      <v-window-item value="start">
        <v-toolbar elevation="0">
          <v-btn
            class="bg-light"
            elevation="0"
            @click="closeNewAppointmentModal()"
          >
            <v-icon size="15" class="">fas fa-times</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Neuer Termin</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>

        <v-divider></v-divider>
        <v-form
          v-model="validationState"
          ref="registerCompanyForm"
          @submit.prevent
        >
          <v-list class="pa-0">
            <v-list-item
              @click="tab = 'project'"
              :disabled="selectedType != 'provider'"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Projekt</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div
                  class="mr-3 pt-1 secondary--text text-md"
                  v-if="selectedType == 'provider'"
                >
                  <span v-if="newAppointment.project.title">{{
                    newAppointment.project.title
                  }}</span>
                </div>
                <div class="mr-3 pt-1 secondary--text text-md" v-else>
                  <span v-if="selectedProject.title">{{
                    selectedProject.title
                  }}</span>
                </div>
                <div v-if="selectedType == 'provider'">
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'datestart'">
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bolder text-md"
                  :class="newAppointment.start ? '' : 'text-danger'"
                  >Termin Start</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  {{ newAppointment.start | moment("DD.MM.YYYY - HH:mm") }}
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="tab = 'dateend'">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Termin Ende</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon>
                <div class="mr-3 pt-1 secondary--text text-md">
                  {{ newAppointment.end | moment("DD.MM.YYYY - HH:mm") }}
                </div>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
          <div class="mx-4 mt-3">
            <div>
              <span
                :class="newAppointment.description ? '' : 'text-danger'"
                class="font-weight-bolder"
                >Anlass</span
              >
            </div>
            <v-text-field
              placeholder="Anlass eintragen..."
              class="rounded-lg mt-1"
              v-model="newAppointment.description"
              filled
              rounded
              dense
              :rules="formRules.reasonRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
        </v-form>
      </v-window-item>
      <v-window-item value="project">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Projekte</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pa-0">
          <div
            class="mb-2 bg-light pa-3 border-radius-lg mx-3"
            style="cursor: pointer"
            v-for="project in projects.data"
            :key="project.id"
            @click="selectProject(project)"
          >
            <v-row class="align-center">
              <v-avatar
                size="50"
                class="ma-3 text-uppercase text-dark"
                color="grey"
              >
                <v-img
                  :src="getImage(project.imagePath)"
                  :alt="project.title"
                  v-on:error="onImgError"
                />
              </v-avatar>
              <v-col>
                <div>
                  <div class="text-dark">
                    {{ project.title }}
                  </div>
                  <div class="text-body">
                    <span v-if="project.serviceProvider">
                      {{
                        `${project.serviceProvider.name || ""} ${
                          project.serviceProvider.legalForm || ""
                        }`
                      }}</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-list>
      </v-window-item>
      <v-window-item value="datestart">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Termin start</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="mt-4 text-center mx-5">
          <v-datetime-picker
            label="Termin start wählen"
            v-model="newAppointment.start"
            :okText="'Ok'"
            :clearText="'Löschen'"
            :dateIcon="null"
            :datePickerProps="{ min: new Date().toISOString() }"
            :timePickerProps="{ format: '24hr' }"
          >
          </v-datetime-picker>
        </div>
      </v-window-item>
      <v-window-item value="dateend">
        <v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Termin Ende</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="mt-4 text-center mx-5">
          <v-datetime-picker
            label="Termin ende wählen"
            v-model="newAppointment.end"
            :okText="'Ok'"
            :clearText="'Löschen'"
            :dateIcon="null"
            :timePickerProps="{ format: '24hr' }"
          >
          </v-datetime-picker>
        </div>
      </v-window-item>
      <v-window-item value="placeholder"></v-window-item>
    </v-window>
    <v-card-actions
      class="mb-2"
      style="position: absolute; bottom: 0; left: 0; right: 0"
      v-if="tab == 'start'"
    >
      <v-btn
        :disabled="
          !newAppointment.start ||
          !newAppointment.description ||
          newAppointment.description.length < 2
        "
        block
        elevation="0"
        :ripple="false"
        height="43"
        class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
        small
        @click="submitForm()"
        ><span v-if="!loadingAppointments">Speichern</span>
        <v-progress-circular
          v-else
          indeterminate
          color="white"
        ></v-progress-circular
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>
    
<script>
import Teammember from "../Cards/Teammember.vue";
import Customer from "../Cards/Customer.vue";

import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewAppointmentModal",
  data() {
    return {
      fileInput: null,
      previewUrl: "",
      validationState: false,
      tab: null,
      items: [{ text: "Rechtsform", icon: "fas fa-chevron-right" }],
      newAppointment: { project: {} },
      newAppointmentDefault: { project: {} },
    };
  },
  components: { Teammember, Customer },
  mounted() {
    if (this.selectedType != "provider") {
      this.newAppointment.project == this.selectProject;
    }
  },
  methods: {
    onFileChange: function (event) {
      const file = this.fileInput;
      if (!file) {
        return false;
      }
      if (!file.type.match("image.*")) {
        return false;
      }
      const reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        that.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    submitForm() {
      function toIsoString(date) {
        var tzo = -date.getTimezoneOffset(),
          dif = tzo >= 0 ? "+" : "-",
          pad = function (num) {
            return (num < 10 ? "0" : "") + num;
          };

        return (
          date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate()) +
          "T" +
          pad(date.getHours()) +
          ":" +
          pad(date.getMinutes()) +
          ":" +
          pad(date.getSeconds()) +
          dif +
          pad(Math.floor(Math.abs(tzo) / 60)) +
          ":" +
          pad(Math.abs(tzo) % 60)
        )
          .replace("T", " ")
          .replace("Z", "")
          .split("+")[0];
      }

      this.$refs.registerCompanyForm.validate();
      let newAppointment = {
        description: this.newAppointment.description,
        rawDate: toIsoString(this.newAppointment.start),
        rawDateEnd: this.newAppointment.end
          ? toIsoString(this.newAppointment.end)
          : toIsoString(this.newAppointment.start),
        appointmentTime: "",
        appointmentDate: toIsoString(this.newAppointment.start),
        accepted: false,
        projectId:
          this.selectedType == "customer"
            ? this.selectedProject.id
            : this.newAppointment.project.id,
        customerId:
          this.selectedType == "customer"
            ? this.selectedCompany.id
            : this.newAppointment.project.customer
            ? this.newAppointment.project.customer.id
            : null,
        providerId:
          this.selectedType == "customer"
            ? this.selectedProject.companyId
            : this.selectedCompany.id,
        sentByProvider: this.selectedType == "provider",
        sentByCustomer: this.selectedType == "customer",
      };

      if (this.validationState) {
        this.$store.commit("loadingAppointments", true);
        axiosAuth
          .post("/appointments", newAppointment, {
            headers: {
              version: "v1.1",
            },
          })
          .then(async (res) => {
            this.$store.commit("loadingAppointments", false);
            if (this.selectedType == "provider") {
              this.$store.dispatch("getProjects", this.selectedCompany.id);
            } else {
              this.$store.dispatch(
                "getSelectedProject",
                this.selectedProject.id
              );
            }
            this.closeNewAppointmentModal();
          })
          .catch((error) => {
            console.log(error.response);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data.message);
            this.$store.commit("loadingAppointments", false);
          });
      }
    },
    selectProject(project) {
      this.newAppointment.project = project;
      this.tab = "start";
    },
  },
};
</script>
    
    <style>
</style>