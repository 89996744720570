<template>
  <v-card class="card-shadow border-radius-xl pb-4">
    <v-window v-model="tab">
      <v-window-item value="start"
        ><v-toolbar elevation="0">
          <v-btn
            class="bg-light"
            elevation="0"
            @click="closeCompanyDetailModal()"
          >
            <v-icon size="15" class="text-secondary"
              >fas fa-chevron-left</v-icon
            >
          </v-btn>

          <v-spacer></v-spacer>
        </v-toolbar>

        <div class="white">
          <v-row class="justify-center ma-0 pa-0 mb-10">
            <div>
              <v-img
                src="@/assets/img/soap.png"
                class=""
                width="150px"
                height="auto"
                contain
              >
              </v-img>
            </div>
          </v-row>
        </div>

        <v-divider></v-divider>
        <v-row class="align-center pa-0 ma-0 px-2">
          <v-avatar
            size="50"
            class="ma-3 text-uppercase text-dark"
            color="#F3F5F6"
          >
            <v-img
              :src="getImage(userInformations.avatar)"
              :alt="userInformations.firstname"
              v-on:error="onImgError"
            />
          </v-avatar>
          <v-col>
            <div class="my-1 font-weight-bolder text-md">Firmendaten</div>
            <div class="my-1 font-weight-normal text-md">
              <p class="mb-0 text-typo">Erzähle uns mehr über dein Business</p>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-form
          v-model="validationState"
          ref="registerCompanyForm"
          @submit.prevent
        >
          <v-list class="pa-0">
            <v-list-item-group color="primary">
              <v-list-item @click="tab = 'legalform'">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bolder text-md"
                    >Rechtsform</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <div class="mr-3 pt-1 secondary--text text-md">
                    {{ newCompany.legalForm }}
                  </div>
                  <div>
                    <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                  </div>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider></v-divider>
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md"
                  >Umsatzsteuerbefreit</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-icon class="mt-1">
                <v-switch
                  class="mt-2"
                  v-model="newCompany.salesTaxExemption"
                  hide-details
                  inset
                  color="green"
                ></v-switch>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <div v-if="!newCompany.salesTaxExemption">
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Umsatzsteuer-ID *</span>
              </div>
              <v-text-field
                :required="!newCompany.salesTaxExemption"
                placeholder="DE 123456789"
                class="rounded-lg mt-1"
                :rules="formRules.vatRules"
                v-model="newCompany.vat"
                filled
                rounded
                dense
              ></v-text-field>
            </div>
            <div class="mx-4 mt-3">
              <div>
                <span class="font-weight-bolder">Umsatzsteuer in % *</span>
              </div>
              <v-text-field
                :required="!newCompany.salesTaxExemption"
                placeholder="19"
                class="rounded-lg mt-1"
                :rules="formRules.vatValueRules"
                v-model="newCompany.vatValue"
                type="number"
                filled
                rounded
                dense
              ></v-text-field>
            </div>
          </div>

          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder"
                >Firmenname (ohne Rechtsform)*</span
              >
            </div>
            <v-text-field
              placeholder="Muster GmbH"
              class="rounded-lg mt-1"
              v-model="newCompany.name"
              filled
              rounded
              dense
              :rules="formRules.companyNameRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">Straße*</span>
            </div>
            <v-text-field
              placeholder="Musterstraße 1"
              class="rounded-lg mt-1"
              v-model="newCompany.street"
              filled
              rounded
              dense
              :rules="formRules.streetRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">Postleitzahl*</span>
            </div>
            <v-text-field
              placeholder="12345"
              class="rounded-lg mt-1"
              v-model="newCompany.zip"
              filled
              rounded
              dense
              :rules="formRules.zipRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">Ort*</span>
            </div>
            <v-text-field
              placeholder="Musterstadt"
              class="rounded-lg mt-1"
              v-model="newCompany.city"
              filled
              rounded
              dense
              :rules="formRules.cityRules"
              required
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">E-Mail</span>
            </div>
            <v-text-field
              placeholder="Musterstraße 1"
              class="rounded-lg mt-1"
              v-model="newCompany.email"
              filled
              rounded
              dense
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="mx-4 mt-3">
            <div>
              <span class="font-weight-bolder">Telefon</span>
            </div>
            <v-text-field
              placeholder="0123-4567890"
              class="rounded-lg mt-1"
              v-model="newCompany.phone"
              filled
              rounded
              dense
            ></v-text-field>
          </div>
          <v-divider></v-divider>
        </v-form>
        <v-card-actions class="mx-2">
          <v-btn
            :disabled="!validationState"
            block
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
            small
            @click="submitForm"
            ><span v-if="!loadingCompanies">Speichern</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular
          ></v-btn> </v-card-actions
      ></v-window-item>
      <v-window-item value="legalform"
        ><v-toolbar elevation="0">
          <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-toolbar-title>
            <div class="font-weight-bolder text-xl">Rechtsform</div>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <div style="width: 64px"></div>
        </v-toolbar>
        <v-list class="pa-0">
          <div
            v-for="(legalform, index) in legalforms"
            :key="legalform + index"
          >
            <v-list-item
              @click="
                newCompany.legalForm = legalform.title;
                tab = 'start';
              "
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bolder text-md">{{
                  legalform.title
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <div>
                  <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                </div>
              </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
          </div> </v-list
      ></v-window-item>
    </v-window>
  </v-card>
</template>
  
  <script>
export default {
  name: "NewCompanyModal",
  data() {
    return {
      tab: null,
      items: [{ text: "Rechtsform", icon: "fas fa-chevron-right" }],
      newCompany: {},
      validationState: null,
    };
  },
  methods: {
    selectType(type) {
      this.$store.commit("selectedType", type);
      this.selectCompanyModal = false;
    },
    submitForm() {
      this.$refs.registerCompanyForm.validate();
      if (this.validationState)
        this.$store.dispatch("postNewCompany", this.newCompany);
    },
  },
  computed: {},
};
</script>
  
  <style>
</style>