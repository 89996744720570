<template>
  <div v-if="selectedUser">
    <v-card class="card-shadow border-radius-xl">
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="closeUserInfoModal()">
          <v-icon size="15" class="">fas fa-times</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Teammitglied</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div style="width: 60px"></div>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row class="justify-center pt-3 pb-5 px-9">
        <div class="text-center">
          <v-avatar
            size="150"
            class="ma-3 text-uppercase text-dark bg-gradient-warning"
          >
            <v-img
              :src="getImage(selectedUser.avatar)"
              :alt="selectedUser.firstname"
              v-on:error="onImgError"
            />
          </v-avatar>
        </div>
      </v-row>
      <v-list class="pa-0" v-if="selectedUser">
        <v-divider></v-divider>
        <v-list-item style="min-height: 60px">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Firma</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="selectedUser"
                >{{ selectedCompany.name }} {{ selectedCompany.legalForm }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 60px">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Vorname</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="selectedUser"
                >{{ `${selectedUser.firstname || ""}` }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 60px">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Nachname</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="selectedUser"
                >{{ `${selectedUser.lastname || ""}` }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item style="min-height: 60px">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >E-Mail</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="selectedUser"
                >{{ `${selectedUser.email || ""}` }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item style="min-height: 60px">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bolder text-md"
              >Telefon</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <div class="mr-3 pt-1 secondary--text text-md">
              <span v-if="selectedUser"
                >{{ `${selectedUser.phone || ""}` }}
              </span>
            </div>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </v-card>
  </div>
</template>
    
<script>
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "selectedUserInfoModal",

  data() {
    return {};
  },

  methods: {},
  computed: {},
};
</script>