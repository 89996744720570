import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        selectedType: null,
        selectTypeModal: false,
        customTab: null
    }
}

const state = getDefaultState()

const getters = {
    selectedType: state => {
        return state.selectedType
    },
    selectTypeModal: state => {
        return state.selectTypeModal
    },
    customTab: state => {
        return state.customTab
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    selectedType: (state, payload) => {
        state.selectedType = payload
        localStorage.setItem('selectedType', payload);
    },
    customTab: (state, payload) => {
        state.customTab = payload
    },
    selectTypeModal: (state, payload) => {
        state.selectTypeModal = payload
        localStorage.setItem('selectTypeModal', payload);
    },
}

const actions = {

}

export default {
    state,
    getters,
    mutations,
    actions
}