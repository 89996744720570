import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        projects: [],
        selectedProject: null,
        loadingProjects: false,
        newProjectModal: false,
        selectProjectModal: null,
        editProjectModal: false,
    }
}

const state = getDefaultState()

const getters = {
    projects: state => {
        return state.projects
    },
    selectedProject: state => {
        return state.selectedProject
    },
    newProjectModal: state => {
        return state.newProjectModal
    },
    selectProjectModal: state => {
        return state.selectProjectModal
    },
    editProjectModal: state => {
        return state.editProjectModal
    },

    loadingProjects: state => {
        return state.loadingProjects
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    projects: (state, payload) => {
        state.projects = payload
    },
    selectedProject: (state, payload) => {
        state.selectedProject = payload
        if (payload)
            localStorage.setItem('selectedProject', JSON.stringify(payload));
    },
    newProjectModal: (state, payload) => {
        state.newProjectModal = payload
    },
    selectProjectModal: (state, payload) => {
        state.selectProjectModal = payload
    },
    editProjectModal: (state, payload) => {
        state.editProjectModal = payload
    },
    loadingProjects: (state, payload) => {
        state.loadingProjects = payload
    },

}

const actions = {

    getSelectedProject: ({ commit }, projectId) => {
        commit('loadingProjects', true)
        axiosAuth.get('/projects/' + projectId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingProjects', false)
                    if (!res.data.message) {
                        commit('selectedProject', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingProjects', false)
                    commit('companies', [])

                }
            )
    },

    getProjects: ({ commit }, companyId) => {
        commit('loadingProjects', true)
        axiosAuth.get('/projects?$limit=100&companyId=' + companyId, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingProjects', false)
                    if (!res.data.message) {
                        commit('projects', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('loadingProjects', false)
                    commit('companies', [])

                }
            )
    },

    postNewProject: ({ commit, dispatch }, payload) => {
        commit('loadingProjects', true)
        axiosAuth.post('/projects', payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {

                    commit('loadingProjects', false)
                    dispatch('getProjects', payload.companyId)
                    commit('newProjectModal', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingProjects', false)

                }
            )
    },

    updateProject: ({ commit, dispatch }, payload) => {

        commit('loadingProjects', true)
        axiosAuth.patch('/projects/' + payload.id, payload, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {

                    commit('loadingProjects', false)
                    dispatch('getProjects', payload.companyId)
                    commit('editProjectModal', false)
                    commit('customTab', null)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingProjects', false)

                }
            )
    },


    deleteProject: ({ commit, dispatch }, payload) => {
        commit('loadingProjects', true)
        axiosAuth.delete('/projects/' + payload.id, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('loadingProjects', false)
                    dispatch('getProjects', payload.companyId)
                    commit('editProjectModal', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('authError', true)
                    commit('authMessage', error.response.data.message)
                    commit('loadingProjects', false)

                }
            )
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}