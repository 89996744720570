<template>
  <v-card class="card-shadow border-radius-xl pb-4">
    <v-toolbar elevation="0">
      <v-btn class="bg-light" elevation="0" @click="$router.go(-1)">
        <v-icon size="15" class="text-secondary">fas fa-chevron-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="white">
      <v-row class="justify-center ma-0 pa-0 mb-10">
        <div>
          <v-img
            src="@/assets/img/soap.png"
            class=""
            width="150px"
            height="auto"
            contain
          >
          </v-img>
        </div>
      </v-row>
    </div>

    <div>
      <h4
        class="text-h3 mx-5 mt-0 mt-md-5 font-weight-bolder text-uppercase text-typo mb-2 text-center"
      >
        Möchstest du dich wirklich ausloggen?
      </h4>
    </div>

    <v-card-actions>
      <v-row class="pa-0 ma-0 px-2">
        <v-col>
          <v-btn
            @click="$store.dispatch('logout')"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-white btn-success bg-success py-2 px-6 me-2 mb-2 w-100"
            small
            ><span>Ja</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="$router.go(-1)"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-white btn-danger bg-danger py-2 px-6 me-2 mb-2 w-100"
            small
            ><span>Nein</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
    
    <script>
export default {
  name: "CompanyDetailModal",
  data() {
    return {
      tab: null,
      items: [{ text: "Rechtsform", icon: "fas fa-chevron-right" }],
    };
  },
  methods: {
    selectType(type) {
      this.$store.commit("selectedType", type);
      this.selectCompanyModal = false;
    },
  },
  mounted() {},
};
</script>
    
    <style>
</style>